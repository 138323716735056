import React from "react";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
// import LowPriorityIcon from "@mui/icons-material/LowPriority";
import ChecklistIcon from "@mui/icons-material/Checklist";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface Props {
  roleViewCompleted: string;
  setRoleViewCompleted: (roleViewCompleted: string) => void;
}

export const RoleViewCompletedSelector: React.FC<Props> = ({ roleViewCompleted, setRoleViewCompleted }) => {
  const handleViewChange = (event: React.MouseEvent<HTMLElement>, newRoleViewCompleted: string) => {
    if (newRoleViewCompleted === null) return;
    setRoleViewCompleted(newRoleViewCompleted);
  };

  return (
    <ToggleButtonGroup
      exclusive
      value={roleViewCompleted}
      onChange={handleViewChange}
      aria-label="role view completed setting"
    >
      <ToggleButton value="Normal" aria-label="Normal">
        <Tooltip title="Show Completed Groups Normally">
          <ChecklistIcon />
        </Tooltip>
      </ToggleButton>
      {/* <ToggleButton value="MoveDown" aria-label="MoveDown">
        <Tooltip title="Move Completed Groups to the Bottom">
          <LowPriorityIcon />
        </Tooltip>
      </ToggleButton> */}
      <ToggleButton value="Hide" aria-label="Hide">
        <Tooltip title="Hide Completed Groups">
          <VisibilityOffIcon />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
