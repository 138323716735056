import React from "react";
import { IconButton, Stack, TextField } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { PromptForDateDialog } from "./dialogs/promptForDateDialog";
import { formatDateSimple, parseDateSimple } from "../util/dateUtils";

interface Props {
  label: string;
  dateString: string;
  setDateString: (dateString: string) => void;
  required?: boolean;
}

export const DateFieldWithPicker: React.FC<Props> = ({ label, dateString, setDateString, required }) => {
  const [datePickerIsOpen, setDatePickerIsOpen] = React.useState(false);

  return (
    <Stack direction="row" spacing={1} alignItems="flex-start">
      <IconButton
        onClick={() => {
          setDatePickerIsOpen(true);
        }}
      >
        <CalendarMonthIcon fontSize="large" />
      </IconButton>
      <TextField
        required={required}
        label={label}
        value={dateString}
        variant="outlined"
        sx={{ minWidth: 250, width: "100%" }}
        color="primary"
        onChange={(e) => setDateString(e.target.value)}
        error={parseDateSimple(dateString) === undefined}
        helperText={"Format: YYYY-MM-DD"}
      />
      <PromptForDateDialog
        title={label}
        currentDate={(() => {
          try {
            return new Date(dateString);
          } catch {
            return new Date();
          }
        })()}
        open={datePickerIsOpen}
        onClose={() => {
          setDatePickerIsOpen(false);
        }}
        buttonLabel="OK"
        onSave={async (date: Date, onSuccess: () => void, onError: (msg: string) => void): Promise<void> => {
          setDateString(formatDateSimple(date));
          onSuccess();
        }}
      />
    </Stack>
  );
};
