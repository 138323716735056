import React, { ReactNode } from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";

interface Props {
  title: string | ReactNode;
  variant?: Variant;
  flexGrow?: number;
  children?: ReactNode;
}

export const PaperWithTitle: React.FC<Props> = ({ title, variant, flexGrow, children }) => {
  return (
    <Paper sx={{ p: 3, flexGrow }}>
      <Stack spacing={2}>
        <Typography variant={variant ?? "h5"}>{title}</Typography>
        {children}
      </Stack>
    </Paper>
  );
};
