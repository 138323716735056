import React from "react";
import { Menu, MenuItem } from "@mui/material";
import { PlanRole, PlannedBlockType, RoleBlockType } from "../../../util/modelTypes";

interface Props {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  rolesList: PlanRole[];
  role?: PlanRole;
  plannedBlockType?: PlannedBlockType;
  blockNumber: number;
  setSelectedBlockType: (blockNumber: number, blockType: RoleBlockType) => void;
  blockTypes: RoleBlockType[];
}

export const TimeBlockMenu: React.FC<Props> = ({
  open,
  anchorEl,
  onClose,
  rolesList,
  role,
  plannedBlockType,
  blockNumber,
  setSelectedBlockType,
  blockTypes,
}) => {
  return (
    <Menu
      id={`time-block-menu-${blockNumber}`}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": "time-block-button",
        role: "listbox",
      }}
    >
      {blockTypes.map((option, index) => (
        <MenuItem
          key={option.name}
          data-testid={`TimeBlockMenuItem-${index}`}
          selected={option.role === role && option.blockType === plannedBlockType}
          onClick={() => {
            setSelectedBlockType(blockNumber, option);
            onClose();
          }}
        >
          {option.name}
        </MenuItem>
      ))}
    </Menu>
  );
};
