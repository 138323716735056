import {
  TodayPageState,
  addNewTaskToPlan,
  addTaskToPlan,
  deletePlannedTask,
  setDailyPlanId,
  setEndTimeBlockNumber,
  setStartTimeBlockNumber,
  unplanTask,
} from "../../todayPageSlice";
import { BacklogState, addUnplannedTask, deleteUnplannedTask } from "../../backlogSlice";
import { PlanTask, PlanTaskGroup } from "../../../../util/modelTypes";
import {
  requestAddNewTaskToPlan,
  requestAddTaskToPlan,
  requestUnplanTask,
} from "../../../../generated/graphqlWrappers";
import { showSnackbarError } from "../../../../components/appSnackbarSlice";
import { optimisticUpdateAndServerRequestWithUndo } from "./api.utils";
import { uuidForId } from "../../../../util/uuidUtils";
import { RoutinesState } from "../../routinesSlice";
import { TodayOptimisticAPIContext } from "./today.api.plan";

export const localToggleSelectedRole = <T extends TodayPageState | BacklogState | RoutinesState>(
  state: T,
  roleId: string,
) => {
  const role = state.roles[roleId];
  if (role) {
    if (state.selectedRoles[roleId]) {
      delete state.selectedRoles[roleId];
    } else {
      state.selectedRoles[roleId] = role;
    }
  }
  const selectedRoleIds = Object.keys(state.selectedRoles);
  if (selectedRoleIds.length > 0 && state.selectedRoles[state.defaultRoleId] === undefined) {
    state.defaultRoleId = selectedRoleIds[0];
  }
};

export const apiPlanTask = (apiContext: TodayOptimisticAPIContext, task: PlanTask) => {
  const { client, todayPageState, settingsState, dispatch } = apiContext;
  const oldDailyPlanId = todayPageState.dailyPlanId;
  const hasDailyPlan = todayPageState.dailyPlanId !== "";
  const dailyPlanId = hasDailyPlan ? todayPageState.dailyPlanId : uuidForId();

  // When you plan a task, a new group will be created for it.
  const taskGroupId = uuidForId();
  const taskGroupOrder = (todayPageState.taskGroups[todayPageState.taskGroups.length - 1]?.order ?? 0) + 1000;
  dispatch(deleteUnplannedTask({ taskId: task.id }));
  dispatch(addTaskToPlan({ taskGroupId, taskGroupOrder, task, grayIsDefault: settingsState.grayIsDefault }));

  if (!hasDailyPlan) {
    dispatch(setDailyPlanId({ dailyPlanId }));
    dispatch(setStartTimeBlockNumber({ startTimeBlockNumber: settingsState.defaultStartTimeBlockNumber }));
    dispatch(setEndTimeBlockNumber({ endTimeBlockNumber: settingsState.defaultEndTimeBlockNumber }));
  }

  requestAddTaskToPlan(
    client,
    {
      dailyPlanId,
      date: todayPageState.date,
      taskGroupId,
      taskGroupOrder,
      taskId: task.id,
      isGrayTime: settingsState.grayIsDefault,
    },
    () => {},
    (err) => {
      dispatch(setDailyPlanId({ dailyPlanId: oldDailyPlanId }));
      dispatch(unplanTask({ taskId: task.id }));
      dispatch(addUnplannedTask({ taskId: task.id, name: task.name, order: task.order, roleId: task.roleId }));
      dispatch(showSnackbarError(err));
    },
  );
};

export const apiUnplanTask = (
  apiContext: TodayOptimisticAPIContext,
  taskGroup: PlanTaskGroup,
  task: PlanTask,
  grayIsDefault: boolean,
) => {
  const { client, dispatch } = apiContext;
  const taskGroupName = taskGroup.name;
  const timeBlockCount = taskGroup.timeBlockCount;
  const completedTimeBlockCount = taskGroup.completedTimeBlockCount;
  const taskGroupOrder = taskGroup.order;
  const routineId = taskGroup.routineId;
  const isGrayTime = taskGroup.isGrayTime;

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: [
      unplanTask({ taskId: task.id }),
      addUnplannedTask({ taskId: task.id, name: task.name, order: task.order, roleId: task.roleId }),
    ],
    request: requestUnplanTask,
    variables: { taskId: task.id },
    undo: [
      addTaskToPlan({
        taskGroupId: taskGroup.id,
        taskGroupOrder,
        task,
        grayIsDefault,
        recoveryData: { taskGroupName, timeBlockCount, completedTimeBlockCount, routineId, isGrayTime },
      }),
      deleteUnplannedTask({ taskId: task.id }),
    ],
  });
};

export function apiAddNewTaskToPlan(
  apiContext: TodayOptimisticAPIContext,
  name: string,
  roleId: string,
  grayIsDefault: boolean,
  setAddTaskInFlight: (inFlight: boolean) => void,
) {
  const { client, todayPageState, backlogState, settingsState, dispatch } = apiContext;
  const hasDailyPlan = todayPageState.dailyPlanId !== "";
  const oldDailyPlanId = todayPageState.dailyPlanId;
  const dailyPlanId = hasDailyPlan ? todayPageState.dailyPlanId : uuidForId();

  // A new task will also create a new taskGroup
  const taskId = uuidForId();
  const taskGroupId = uuidForId();
  const order = (backlogState.unplannedTasks[backlogState.unplannedTasks.length - 1]?.order ?? 0) + 1000;
  const taskGroupOrder = (todayPageState.taskGroups[todayPageState.taskGroups.length - 1]?.order ?? 0) + 1000;
  const isGrayTime = grayIsDefault;

  if (!hasDailyPlan) {
    dispatch(setDailyPlanId({ dailyPlanId }));
    dispatch(setStartTimeBlockNumber({ startTimeBlockNumber: settingsState.defaultStartTimeBlockNumber }));
    dispatch(setEndTimeBlockNumber({ endTimeBlockNumber: settingsState.defaultEndTimeBlockNumber }));
  }

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    onStart: () => setAddTaskInFlight(true),
    onEnd: () => setAddTaskInFlight(false),
    optimisticUpdate: addNewTaskToPlan({ taskGroupId, taskGroupOrder, taskId, roleId, name, order, grayIsDefault }),
    request: requestAddNewTaskToPlan,
    variables: {
      dailyPlanId,
      date: todayPageState.date,
      taskGroupId,
      taskGroupOrder,
      taskId,
      roleId,
      name,
      order,
      isGrayTime,
    },
    undo: [deletePlannedTask({ taskId }), setDailyPlanId({ dailyPlanId: oldDailyPlanId })],
  });
}
