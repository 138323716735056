import { useEffect, useState } from "react";
import { ApolloProvider } from "@apollo/client";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Provider } from "react-redux";
import { apolloClient } from "./apolloClient";
import { AppRoutes } from "./pages/appRoutes";
import { setAccessToken, setSiteAdmin } from "./util/auth";
import { store } from "./store/store";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

function App() {
  const [loading, setLoading] = useState(true);

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

  // When we first load, try to get a new access token from the refresh token route
  // If we have logged in, a refresh token cookie will have been set. This route
  // will turn that into an access token. It will also let us know if the user is a
  // site admin. This only affects some UI, but won't give you any data access since that
  // will also be checked by any route or GQL request based on your authorization.
  useEffect(() => {
    fetch("/refresh-token", { method: "POST", credentials: "include" })
      .then(async (response) => {
        const { accessToken, isSiteAdmin } = await response.json();
        setAccessToken(accessToken);
        setSiteAdmin(isSiteAdmin);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ApolloProvider client={apolloClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Provider store={store}>
          <AppRoutes />
        </Provider>
      </LocalizationProvider>
    </ApolloProvider>
  );
}

export default App;
