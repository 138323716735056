import React, { useEffect } from "react";
import { Box, Stack } from "@mui/material";
import { compareDates } from "../../../util/dateUtils";
import { TimeSelect } from "../../../components/timeSelect";
import { range } from "../../../util/arrayUtils";
import { timerEffectFn } from "./utils/timerEffect";
import { GuideRailTimeBlock, GuideRailTimeAPIBlockSetters } from "./guideRailTimeBlock";
import { PlanRole, RoleBlockType } from "../../../util/modelTypes";

export interface GuideRailAPISetters extends GuideRailTimeAPIBlockSetters {
  setStartTimeBlockNumber: (startTimeBlockNumber: number) => void;
  setEndTimeBlockNumber: (endTimeBlockNumber: number) => void;
}

interface GuideRailProps extends GuideRailAPISetters {
  guideRailDate: Date;
  startTimeBlockNumber: number;
  endTimeBlockNumber: number;
  rolesList: PlanRole[];
  plannedBlocks: RoleBlockType[];
}

export const GuideRail: React.FC<GuideRailProps> = (props) => {
  const { guideRailDate, startTimeBlockNumber, endTimeBlockNumber, rolesList, plannedBlocks } = props;
  const { setStartTimeBlockNumber, setEndTimeBlockNumber } = props as GuideRailAPISetters;
  const guideRailTimeBlockAPISetters = props as GuideRailTimeAPIBlockSetters;

  const [timePct, setTimePct] = React.useState(0);

  const [startTimeUsed, setStartTimeUsed] = React.useState(0);
  const [endTimeUsed, setEndTimeUsed] = React.useState(0);

  const [currentBlock, setCurrentBlock] = React.useState(0);

  const numberOfBlocks = endTimeBlockNumber - startTimeBlockNumber + 1;
  const blockSize = numberOfBlocks > 25 ? 20 : 25;

  const updateTimePct = (date: Date) => {
    const cmp = compareDates(guideRailDate, date);
    if (cmp === 0) {
      const currentTimeInMinutes = Math.max(date.getHours() * 60 + date.getMinutes() - startTimeBlockNumber * 30, 0);
      const currentTimeInBlocks = currentTimeInMinutes / 30;
      setTimePct(Math.min(100, (currentTimeInBlocks / numberOfBlocks) * 100));
    } else if (cmp < 0) {
      setTimePct(100);
    } else {
      setTimePct(0);
    }
    setStartTimeUsed(startTimeBlockNumber);
    setEndTimeUsed(endTimeBlockNumber);
  };

  useEffect(() => {
    return timerEffectFn(true, 1000 * 30, updateTimePct);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startTimeBlockNumber,
    endTimeBlockNumber,
    setTimePct,
    setStartTimeUsed,
    setEndTimeUsed,
    startTimeBlockNumber,
    numberOfBlocks,
  ]);

  if (startTimeBlockNumber !== startTimeUsed || endTimeBlockNumber !== endTimeUsed) {
    updateTimePct(new Date());
  }

  return (
    <Stack direction="row" spacing={2} sx={{ my: 1 }}>
      <TimeSelect label="Start Time" timeBlock={startTimeBlockNumber} setTimeBlock={setStartTimeBlockNumber} />
      <Stack direction="row" sx={{}} alignItems="center" position="relative">
        <Box
          position="absolute"
          sx={{
            top: 0,
            left: "-5px",
            pl: timePct === 0 ? undefined : "5px",
            pr: timePct === 100 ? "5px" : undefined,
            backgroundColor: "#00000033",
            height: "100%",
            width: `${timePct}%`,
          }}
        />
        {range(numberOfBlocks).map((blockIndex) => (
          <GuideRailTimeBlock
            key={blockIndex}
            {...guideRailTimeBlockAPISetters}
            startTimeBlockNumber={startTimeBlockNumber}
            blockSize={blockSize}
            blockIndex={blockIndex}
            rolesList={rolesList}
            role={plannedBlocks[blockIndex + startTimeBlockNumber]?.role}
            plannedBlockType={plannedBlocks[blockIndex + startTimeBlockNumber]?.blockType}
            currentBlock={currentBlock}
            setCurrentBlock={setCurrentBlock}
          />
        ))}
      </Stack>
      <TimeSelect label="End Time" isEndTime timeBlock={endTimeBlockNumber} setTimeBlock={setEndTimeBlockNumber} />
    </Stack>
  );
};
