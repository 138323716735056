import React from "react";
import { FormControlLabel, FormGroup, Stack, Switch } from "@mui/material";
import { AppLayout } from "../../components/layouts/appLayout";
import { useParams } from "react-router-dom";
import { PaperWithTitle } from "../../components/paperWithTitle";
import { AppErrorPage } from "../../components/appErrorPage";
import Cookies from "js-cookie";

type Params = {
  flag: string;
};

export const FeatureFlagPage: React.FC = () => {
  const { flag } = useParams<Params>();
  const [flagIsSet, setFlagIsSet] = React.useState(Cookies.get(flag ?? "") === "true");

  const isValidFlag = (flag?: string): flag is string => {
    // no valid flags right now
    return false;
  };

  // there are no valid flags right now
  if (!isValidFlag(flag)) {
    return <AppErrorPage errorMessage="Invalid feature flag" />;
  }

  return (
    <AppLayout chosen={"Settings"}>
      <PaperWithTitle title="Feature Flag">
        <Stack spacing={1} direction="row">
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={flagIsSet}
                  onChange={(event) => {
                    Cookies.set(flag, event.target.checked ? "true" : "false");
                    setFlagIsSet(event.target.checked);
                  }}
                />
              }
              label={flag}
            />
          </FormGroup>
        </Stack>
      </PaperWithTitle>
    </AppLayout>
  );
};
