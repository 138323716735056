import React from "react";
import { Stack } from "@mui/material";
import { PlannedTask, TaskEditProps } from "./plannedTask";
import { PlanRole, PlanTask, PlanTaskGroup } from "../../../util/modelTypes";
import { Dictionary } from "@reduxjs/toolkit";

interface Props extends TaskEditProps {
  taskGroup: PlanTaskGroup;
  tasks: PlanTask[];
  roles: Dictionary<PlanRole>;
  rolesList: PlanRole[];
  shouldBlockUI: boolean;
  setEditingInProgress: (block: boolean) => void;
  autoAddModeGroup: string;
  setAutoAddModeGroup: (autoAddModeGroup: string) => void;
}

export const TaskGroupTasks: React.FC<Props> = ({
  taskGroup,
  tasks,
  roles,
  rolesList,
  isEditingTaskId,
  setIsEditingTaskId,
  editTaskName,
  setTaskRole,
  toggleTaskCompletion,
  unplanTask,
  deleteTask,
  groupTask,
  ungroupTask,
  shouldBlockUI,
  setEditingInProgress,
  autoAddModeGroup,
  setAutoAddModeGroup,
  autoAddTask,
}) => {
  const firstIncompleteTask = tasks.find((task) => !task.completed) ?? tasks[0];
  const tasksToShow = taskGroup.collapsed ? [firstIncompleteTask] : tasks;

  return (
    <Stack sx={{ flexGrow: 1 }} width="calc(100% - 120px)">
      {tasksToShow.map((task) => (
        <PlannedTask
          key={task.id}
          task={task}
          taskGroup={taskGroup}
          isEditingTaskId={isEditingTaskId}
          setIsEditingTaskId={setIsEditingTaskId}
          editTaskName={editTaskName}
          toggleTaskCompletion={toggleTaskCompletion}
          unplanTask={unplanTask}
          deleteTask={deleteTask}
          ungroupTask={tasks.length > 1 ? ungroupTask : undefined}
          groupTask={tasks.length === 1 ? groupTask : undefined}
          shouldBlockUI={shouldBlockUI}
          setEditingInProgress={setEditingInProgress}
          roles={roles}
          rolesList={rolesList}
          setTaskRole={setTaskRole}
          autoAddModeGroup={autoAddModeGroup}
          setAutoAddModeGroup={setAutoAddModeGroup}
          autoAddTask={autoAddTask}
        />
      ))}
    </Stack>
  );
};
