import { RoutinesState } from "../routinesSlice";
import { PlanTaskGroup, RoutinePlanTaskGroup } from "../../../util/modelTypes";

export const routinesForDay = (
  routinesState: RoutinesState,
  taskGroups: PlanTaskGroup[],
  date: Date,
): RoutinePlanTaskGroup[] => {
  return routinesState.routines.filter(
    (routine) =>
      (routine.daysOfTheWeek & (1 << date.getDay())) !== 0 &&
      !routine.archived &&
      !taskGroups.some((taskGroup) => taskGroup.routineId === routine.id),
  );
};
