import { ApolloClient, DocumentNode } from "@apollo/client";
import { SuccessResponse } from "../generated/graphql";

export const gqlQuery = async (
  apolloClient: ApolloClient<object>,
  query: DocumentNode,
  variables: any,
  onSuccess: (data: any) => void,
  onError: (err: string) => void,
): Promise<void> => {
  return apolloClient
    .query({ query, variables, fetchPolicy: "network-only" })
    .then((response) => {
      if (response.error) {
        onError(response.error.message);
      } else if (!response.loading && response.data) {
        onSuccess(response.data);
      }
    })
    .catch((error) => {
      if (process.env.JEST_WORKER_ID === undefined) {
        console.log(error);
      }
      onError("Failed to make request");
    });
};

export const gqlMutate = async (
  apolloClient: ApolloClient<object>,
  mutation: DocumentNode,
  responseProperty: string,
  variables: any,
  onSuccess: (response: SuccessResponse) => void,
  onError: (err: string) => void,
): Promise<SuccessResponse> => {
  let errorMessage = "Unknown Error";
  try {
    const response = await apolloClient.mutate({ mutation, variables, fetchPolicy: "network-only" });
    if (response.errors) {
      if (process.env.JEST_WORKER_ID === undefined) {
        console.log(response.errors);
      }
      errorMessage = response.errors[0]?.message ?? errorMessage;
    } else if (response.data) {
      if (response.data[responseProperty].success) {
        onSuccess(response.data[responseProperty]);
        return { success: true, error: "" };
      } else {
        errorMessage = response.data[responseProperty].error;
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      if (process.env.JEST_WORKER_ID === undefined) {
        console.log(error);
      }
      errorMessage = error.message;
    }
  }
  if (process.env.JEST_WORKER_ID === undefined) {
    console.log(errorMessage);
  }
  onError(errorMessage);
  return { success: false, error: errorMessage };
};
