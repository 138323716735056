import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

interface AppState {
  isInitialized: boolean;
  sidebarDrawerIsOpen: boolean;
  backlogDrawerIsOpen: boolean;
  appBarHidden: boolean;
}

const initialState: AppState = {
  isInitialized: false,
  sidebarDrawerIsOpen: false,
  backlogDrawerIsOpen: false,
  appBarHidden: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    initializeAppState: (
      state,
      action: PayloadAction<{ sidebarDrawerIsOpen: boolean; backlogDrawerIsOpen: boolean }>,
    ) => {
      state.isInitialized = true;
      state.sidebarDrawerIsOpen = action.payload.sidebarDrawerIsOpen;
      state.backlogDrawerIsOpen = action.payload.backlogDrawerIsOpen;
      state.appBarHidden = false;
    },
    setSidebarDrawerIsOpen: (state, action: PayloadAction<boolean>) => {
      state.sidebarDrawerIsOpen = action.payload;
    },
    setBacklogDrawerIsOpen: (state, action: PayloadAction<boolean>) => {
      state.backlogDrawerIsOpen = action.payload;
    },
    setAppBarHidden: (state, action: PayloadAction<boolean>) => {
      state.appBarHidden = action.payload;
    },
  },
});

export const { initializeAppState, setSidebarDrawerIsOpen, setBacklogDrawerIsOpen, setAppBarHidden } = appSlice.actions;

export const selectAppState = (state: RootState) => state.app as AppState;

export default appSlice.reducer;
