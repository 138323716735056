import { configureStore } from "@reduxjs/toolkit";
import appReducers from "../pages/appSlice";
import backlogReducers from "../pages/userApp/backlogSlice";
import routinesReducers from "../pages/userApp/routinesSlice";
import snackbarReducers from "../components/appSnackbarSlice";
import todayPageReducers from "../pages/userApp/todayPageSlice";
import settingsPageReducers from "../pages/userApp/settings/settingsSlice";

export const store = configureStore({
  reducer: {
    app: appReducers,
    backlog: backlogReducers,
    routines: routinesReducers,
    snackbar: snackbarReducers,
    todayPage: todayPageReducers,
    settings: settingsPageReducers,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
