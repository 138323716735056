import React, { ReactNode } from "react";
import { Paper, Table, TableBody, TableContainer } from "@mui/material";
import { ObjectTableHeader, TableHeaderButton } from "./objectTableHeader";
import { ObjectTableRow } from "./objectTableRow";
import { EmptyObjectTableRow } from "./emptyObjectTableRow";
import { SortColumnDescriptor } from "./objectArrayTableUtils";

export interface ObjectTableButtonData {
  icon: React.ReactNode;
  text: string;
  onClick: (objectId: number) => void;
}

export interface ChipData {
  color: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
  label: string;
}

export interface TableObject {
  id: string;
  [x: string]: any;
}

export type TableColumnHeader = string | ReactNode;

export interface ObjectArrayTableProps {
  dataDescription: string;
  title: TableColumnHeader | TableColumnHeader[];
  data: TableObject[];
  cellNode: (obj: any, index: number, array: TableObject[]) => ReactNode | ReactNode[];
  onClick: (id: string) => void;
  buttons?: TableHeaderButton[];
  buttonColIndex?: number;
  colWidths?: number[];
  onHeaderClick?: (colIndex: number) => void;
  headerSort?: SortColumnDescriptor;
}

export const ObjectArrayTable: React.FC<ObjectArrayTableProps> = ({
  dataDescription,
  title,
  data,
  cellNode,
  onClick,
  buttons,
  buttonColIndex,
  colWidths,
  onHeaderClick,
  headerSort,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label={dataDescription}>
        <colgroup>
          {(colWidths ?? []).map((cw, i) => (
            <col key={i} width={`${cw}%`} />
          ))}
        </colgroup>
        <ObjectTableHeader
          title={title}
          buttons={buttons}
          buttonColIndex={buttonColIndex}
          onClick={onHeaderClick}
          headerSort={headerSort}
        />
        <TableBody>
          {data.length > 0 ? (
            data.map((obj, index, array) => (
              <ObjectTableRow key={obj.id} obj={obj} onClick={onClick} cellNode={() => cellNode(obj, index, array)} />
            ))
          ) : (
            <EmptyObjectTableRow dataDescription={dataDescription} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
