import React from "react";
import { FormDialog } from "../../../components/dialogs/formDialog";
import { InvitationDialogFields } from "../../../components/dialogs/invitationDialogFields";

interface Props {
  open: boolean;
  onClose: () => void;
  cancel: () => void;
  saveAndClose: (email: string, displayName: string) => void;
}

export const InvitationDialog: React.FC<Props> = ({ open, onClose, cancel, saveAndClose }) => {
  const [email, setEmail] = React.useState("");
  const [displayName, setDisplayName] = React.useState("");
  const [initialized, setInitialized] = React.useState(false);

  if (!initialized) {
    setEmail("");
    setDisplayName("");
    setInitialized(true);
  }

  return (
    <FormDialog
      title="Request an Invitation"
      open={open}
      errorMessage={""}
      saveIsDisabled={function (): boolean {
        return email === "" || displayName === "";
      }}
      cancel={() => {
        setInitialized(false);
        cancel();
      }}
      saveAndClose={() => {
        setInitialized(false);
        saveAndClose(email, displayName);
      }}
      saveButtonLabel={"Request"}
    >
      <InvitationDialogFields
        email={email}
        setEmail={setEmail}
        displayName={displayName}
        setDisplayName={setDisplayName}
      />
    </FormDialog>
  );
};
