import React from "react";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";

export const AppLogo: React.FC = () => {
  return (
    <React.Fragment>
      <Stack direction="row" alignItems="center">
        <Link to="/" style={{ textDecoration: "none", color: "none" }}>
          <img
            src="/momentum-logo.png"
            width="90%"
            height="90%"
            alt="Momentum logo"
            style={{ marginLeft: 8, marginTop: 8 }}
          />
        </Link>
      </Stack>
    </React.Fragment>
  );
};
