import React from "react";
import { Popover, Stack } from "@mui/material";
import { CompactPicker } from "react-color";

interface Props {
  id: string;
  open: boolean;
  chosenColor: string;
  anchorEl: HTMLElement | null;
  onSelect: (color: string) => void;
  handleClose: () => void;
}

export const ColorPickerPopover: React.FC<Props> = ({ chosenColor, id, open, anchorEl, onSelect, handleClose }) => {
  return (
    <Popover
      aria-label="color picker"
      id={id}
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Stack direction="row" spacing={1}>
        <CompactPicker
          onChangeComplete={(color, event) => {
            onSelect(color.hex);
          }}
        />
      </Stack>
    </Popover>
  );
};
