import React, { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { FormDialog } from "./formDialog";

interface Props {
  title?: string;
  currentDate: Date;
  open: boolean;
  onClose: () => void;

  buttonLabel: string;
  onSave: (date: Date, onSuccess: () => void, onError: (msg: string) => void) => Promise<void>;
}

export const PromptForDateDialog: React.FC<Props> = ({ title, currentDate, open, onClose, buttonLabel, onSave }) => {
  const [date, setDate] = useState<Dayjs>(dayjs(currentDate));
  const [errorMessage, setErrorMessage] = useState("");

  const saveIsDisabled = () => {
    return false;
  };

  const closeDialog = () => {
    setErrorMessage("");
    setDate(dayjs(currentDate));
    onClose();
  };

  const saveAndCloseDialog = async () => {
    setErrorMessage("");
    await onSave(
      date.toDate(),
      () => {
        closeDialog();
      },
      (msg) => {
        setErrorMessage(msg);
        return msg;
      },
    );
  };

  return (
    <FormDialog
      title={title}
      open={open}
      cancel={closeDialog}
      errorMessage={errorMessage}
      saveAndClose={saveAndCloseDialog}
      saveIsDisabled={saveIsDisabled}
      saveButtonLabel={buttonLabel}
      extraButtons={[
        {
          label: "Today",
          onClick: () => setDate(dayjs()),
        },
      ]}
    >
      <DateCalendar value={date} onChange={(newValue) => setDate(newValue)} />
    </FormDialog>
  );
};
