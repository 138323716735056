import React, { useEffect } from "react";
import { Alert, Snackbar, SnackbarCloseReason } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { closeSnackbar, selectSnackbar } from "./appSnackbarSlice";

export const AppSnackbar: React.FC = () => {
  const snackbarState = useAppSelector(selectSnackbar);
  const dispatch = useAppDispatch();
  const [initialized, setInitialized] = React.useState(false);

  useEffect(() => {
    if (!initialized) {
      dispatch(closeSnackbar());
      setInitialized(true);
    }
  }, [initialized, dispatch]);

  if (!initialized) {
    return <React.Fragment />;
  }

  const onClose = (_event: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(closeSnackbar());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={snackbarState.open}
      autoHideDuration={process.env.JEST_WORKER_ID === undefined ? 3000 : 500}
      onClose={onClose}
    >
      <Alert variant="filled" severity={snackbarState.severity} sx={{ width: "100%" }}>
        {snackbarState.text}
      </Alert>
    </Snackbar>
  );
};
