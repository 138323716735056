export enum TimerState {
  Running,
  Paused,
  Stopped,
}

export const parseTimerState = (state: string | undefined): TimerState => {
  if (state === "Running") {
    return TimerState.Running;
  } else if (state === "Paused") {
    return TimerState.Paused;
  } else {
    return TimerState.Stopped;
  }
};

export const formatTimerState = (state: TimerState): string => {
  if (state === TimerState.Running) {
    return "Running";
  } else if (state === TimerState.Paused) {
    return "Paused";
  } else {
    return "Stopped";
  }
};
