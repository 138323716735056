import React, { useCallback, useState } from "react";
import { Stack, TextField, Button } from "@mui/material";

interface Props {
  id: string;
  value: string;
  originalValue: string;
  allowEmpty: boolean;
  onChange: (value: string) => void;
  saveEdit: () => void;
  cancelEdit: () => void;
}

export const ModalTextField: React.FC<Props> = ({
  id,
  allowEmpty,
  value,
  originalValue,
  onChange,
  saveEdit,
  cancelEdit,
}) => {
  // React sends a blur event when clicking a button, so we need to delay the blur event
  // to allow the button to register the click. But, it also sends a blur event when we add
  // a textfield from a button, but then quickly does an onfocus. This can happen in the same
  // loop (before a rerender), so we need to handle that case as well.

  // This is a token to cancel the blur event if we quickly refocus but in a rerender
  const [rerenderBlurTimeoutToken, setRerenderBlurTimeoutToken] = useState<NodeJS.Timeout | undefined>(undefined);

  // This is a token to cancel the blur event if we quickly refocus in the same loop
  var inLoopBlurTimeoutToken: NodeJS.Timeout | undefined = undefined;

  const focusOnRenderDelay = 50;
  const blurDelay = 250; // Must be greater than the focusOnRenderDelay
  const disabledSave = (!allowEmpty && value === "") || value === originalValue;

  // Make sure the textfield is focused when it's rendered (autoFocus doesn't work)
  // We must delay so the input has a chance to be put in the DOM
  const textFieldInputRef = useCallback(
    (inputElement: HTMLInputElement) => {
      if (inputElement && id !== "") {
        setTimeout(() => {
          inputElement.focus();
        }, focusOnRenderDelay);
      }
    },
    [id],
  );

  const clearBlurTimeout = () => {
    clearTimeout(rerenderBlurTimeoutToken);
    setRerenderBlurTimeoutToken(undefined);

    clearTimeout(inLoopBlurTimeoutToken);
    inLoopBlurTimeoutToken = undefined;
  };
  return (
    <Stack direction="row" sx={{ flexGrow: 1 }}>
      <TextField
        key={id}
        inputRef={textFieldInputRef}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        variant="standard"
        sx={{ flexGrow: 1 }}
        onKeyUp={(e) => {
          if (e.key === "Enter" && !disabledSave) {
            saveEdit();
          } else if (e.key === "Escape") {
            cancelEdit();
          }
        }}
        onFocus={(event) => {
          event.target.select();
          clearBlurTimeout();
        }}
        onBlur={() => {
          // Give the button time to register the click if that's why we're losing focus
          const token = setTimeout(() => {
            setRerenderBlurTimeoutToken(undefined);
            inLoopBlurTimeoutToken = undefined;
            cancelEdit();
          }, blurDelay);
          setRerenderBlurTimeoutToken(token);
          inLoopBlurTimeoutToken = token;
        }}
      />
      <Button
        onClick={() => {
          clearBlurTimeout();
          saveEdit();
        }}
        disabled={disabledSave}
      >
        OK
      </Button>
      <Button
        onClick={() => {
          clearBlurTimeout();
          cancelEdit();
        }}
      >
        Cancel
      </Button>
    </Stack>
  );
};
