import React, { useState } from "react";
import { Stack, IconButton, Chip, chipClasses, Box, Tooltip } from "@mui/material";
import { PlanTimeBlockButtons } from "../../../components/app/planTimeBlockButtons";
import { textColorForBackgroundColor } from "../../../util/colorUtils";
import { TaskGroupActionMenu, TaskGroupMenuProps } from "./taskGroupActionMenu";
import { TaskEditProps } from "./plannedTask";
import { TaskGroupLabel } from "./draggableTaskGroup";
import CompressIcon from "@mui/icons-material/Compress";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { PlanTaskGroup } from "../../../util/modelTypes";
import { TaskGroupName } from "./taskGroupName";

export interface TaskGroupHeaderProps {
  taskGroupLabels: TaskGroupLabel[];
  toggleTaskGroupCollapsed: (taskGroup: PlanTaskGroup) => void;
  onTimeBlockClick: (taskGroup: PlanTaskGroup, timeBlockIndex: number) => void;
  onCompletedTimeBlockClick: (taskGroup: PlanTaskGroup, timeBlockIndex: number) => void;
  colorForRole: (roleId: string) => string;
  editTaskGroupName: (taskGroup: PlanTaskGroup, name: string) => void;
}

interface Props extends TaskGroupMenuProps, TaskEditProps, TaskGroupHeaderProps {
  taskGroup: PlanTaskGroup;
  isDragging: boolean;
}

export const DraggableTaskGroupHeader: React.FC<Props> = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [actionMenuIsOpen, setActionMenuIsOpen] = React.useState(false);
  const [isEditingGroupName, setIsEditingGroupName] = useState(false);

  const {
    taskGroup,
    taskGroupLabels,
    groupTask,
    setStartTime,
    setGrayTime,
    moveIncompleteTasks,
    addTask,
    putTaskGroupIntoProject,
    makeTaskGroupIntoRoutine,
    updateRoutineFromTaskGroup,
    toggleTaskGroupCollapsed,
    editTaskGroupName,
    isDragging,
  } = props;

  const tasksCannotGroup = taskGroup.tasks.length > 1 || taskGroup.tasks[0].completed;
  const betweenGroupIconsOffset = -24;

  return (
    <Stack width="calc(100% - 10px)" sx={{ flexDirection: "row", gap: 1 }}>
      <TaskGroupName
        isEditing={isEditingGroupName}
        setIsEditing={setIsEditingGroupName}
        taskGroup={taskGroup}
        saveEdit={(value) => {
          editTaskGroupName(taskGroup, value);
        }}
        cancelEdit={() => {}}
      />
      {!isEditingGroupName &&
        taskGroupLabels.map((taskGroupLabel) => (
          <Tooltip
            key={taskGroupLabel.text ?? taskGroupLabel.tooltip}
            title={taskGroupLabel.tooltip}
            enterDelay={2000}
            placement="top"
          >
            <Chip
              sx={{
                alignSelf: "center",
                "&.MuiChip-filled": {
                  backgroundColor: taskGroupLabel.backgroundColor,
                  color: textColorForBackgroundColor(taskGroupLabel.backgroundColor),
                },
                [`& .${chipClasses.icon}`]: {
                  color: textColorForBackgroundColor(taskGroupLabel.backgroundColor),
                  // Fixes material UI look when there is an icon with no label.
                  mr: taskGroupLabel.text ? undefined : -1.4,
                },
              }}
              size="small"
              label={taskGroupLabel.text}
              onClick={taskGroupLabel.onClick}
              icon={taskGroupLabel.icon}
            />
          </Tooltip>
        ))}
      <Box sx={{ flexGrow: 1 }} />
      <PlanTimeBlockButtons {...props} />
      <Tooltip title="Group Actions" placement="top">
        <IconButton
          sx={{ alignSelf: "flex-start" }}
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            setActionMenuIsOpen(true);
          }}
          data-testid="TaskGroupActionMenuButton"
        >
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>
      <TaskGroupActionMenu
        taskGroup={taskGroup}
        anchorEl={anchorEl}
        open={actionMenuIsOpen}
        onClose={() => {
          setActionMenuIsOpen(false);
        }}
        makeTaskGroupIntoRoutine={(taskGroup) => {
          makeTaskGroupIntoRoutine(taskGroup);
          setActionMenuIsOpen(false);
        }}
        putTaskGroupIntoProject={(taskGroup) => {
          putTaskGroupIntoProject(taskGroup);
          setActionMenuIsOpen(false);
        }}
        updateRoutineFromTaskGroup={(taskGroup) => {
          updateRoutineFromTaskGroup(taskGroup);
          setActionMenuIsOpen(false);
        }}
        setStartTime={(taskGroup) => {
          setStartTime(taskGroup);
          setActionMenuIsOpen(false);
        }}
        setGrayTime={(taskGroup, isGrayTime) => {
          setGrayTime(taskGroup, isGrayTime);
          setActionMenuIsOpen(false);
        }}
        moveIncompleteTasks={(taskGroup) => {
          moveIncompleteTasks(taskGroup);
          setActionMenuIsOpen(false);
        }}
        addTask={(taskGroup) => {
          addTask(taskGroup);
          setActionMenuIsOpen(false);
        }}
      />
      {!tasksCannotGroup && groupTask && !isDragging ? (
        <Tooltip title="Combine into a group">
          <IconButton
            sx={{
              top: `${betweenGroupIconsOffset}px`,
              ml: "auto",
              mr: 1,
              height: "40px",
              backgroundColor: "#999",
              ":hover": { backgroundColor: "#666" },
              color: "#fff",
            }}
            onClick={() => {
              groupTask?.(taskGroup.tasks[0], taskGroup, () => true);
            }}
          >
            <CompressIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={taskGroup.collapsed ? "Expand" : "Collapse"}>
          <IconButton
            sx={{
              alignSelf: "flex-start",
              ml: "auto",
              mr: 1,
              height: "40px",
              visibility: taskGroup.tasks.length > 1 ? "visible" : "hidden",
            }}
            onClick={() => {
              toggleTaskGroupCollapsed(taskGroup);
            }}
          >
            {taskGroup.collapsed ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};
