import React from "react";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { textColorForBackgroundColor } from "../../../util/colorUtils";

interface Props {
  compact: boolean;
  numbers: number[];
  labels?: string[];
  color: string;
  highlight: boolean;
  highlightColor: string;
  tooltip: string;
}

export const DashboardRoleNumbers: React.FC<Props> = ({
  compact,
  numbers,
  labels,
  color,
  highlight,
  highlightColor,
  tooltip,
}) => {
  const backgroundColor = highlight ? highlightColor : color;
  const textColor = textColorForBackgroundColor(backgroundColor);
  return (
    <Tooltip title={tooltip}>
      <Stack sx={{ justifyContent: "center", backgroundColor, flexGrow: 1, px: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{}}>
          {numbers.map((num, i) => (
            <Stack key={i} direction="row" sx={{ alignItems: "center", height: "100%" }}>
              {i > 0 ? <Box sx={{ borderRight: `2px solid ${textColor}`, mx: 2, height: "80%" }} /> : <></>}
              <Stack sx={{ alignItems: "center" }}>
                <Typography sx={{ color: textColor, fontSize: compact ? "20px" : "40px", lineHeight: "80%" }}>
                  {num}
                </Typography>
                {i < (labels?.length ?? 0) ? (
                  <Typography sx={{ color: textColor, fontSize: "10px", lineHeight: "80%" }}>{labels?.[i]}</Typography>
                ) : (
                  <></>
                )}
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Tooltip>
  );
};
