import React, { ReactNode } from "react";
import { TableCell, TableRow } from "@mui/material";

interface Props {
  obj: any;
  cellNode: () => ReactNode | ReactNode[];
  onClick: (id: string) => void;
}

export const ObjectTableRow: React.FC<Props> = ({ obj, cellNode, onClick }) => {
  const makeCells = (cellNode: ReactNode | ReactNode[], index?: number): ReactNode[] => {
    if (Array.isArray(cellNode)) {
      return cellNode.flatMap((c, index) => makeCells(c, index));
    }
    return [
      <TableCell
        key={`${obj.id}-${index ?? 0}`}
        onClick={() => {
          onClick(obj.id);
        }}
      >
        {cellNode}
      </TableCell>,
    ];
  };

  return (
    <TableRow key={`${obj.__typename}-${obj.id}`} sx={{ "&:hover": { backgroundColor: "#f8f8f8" }, cursor: "pointer" }}>
      {makeCells(cellNode())}
    </TableRow>
  );
};
