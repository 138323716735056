import React from "react";
import { Box, Checkbox, IconButton, Stack, Tooltip, Typography, checkboxClasses } from "@mui/material";
import { PlanRole, PlanTask, PlanTaskGroup } from "../../../util/modelTypes";
import { colorForRole } from "../today/todayPageStateUtils";
import { RoleMenu } from "./roleMenu";
import { Dictionary } from "@reduxjs/toolkit";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ExpandIcon from "@mui/icons-material/Expand";
import DeleteIcon from "@mui/icons-material/Delete";
import { PlannedTaskTextField } from "./plannedTaskTextField";

export interface TaskEditProps {
  isEditingTaskId: string;
  setIsEditingTaskId: (taskId: string, isEditing: boolean) => void;
  editTaskName: (task: PlanTask, newName: string) => void;
  setTaskRole(task: PlanTask, taskGroup: PlanTaskGroup, role: PlanRole): void;
  toggleTaskCompletion: (task: PlanTask) => void;
  unplanTask: (task: PlanTask, taskGroup: PlanTaskGroup) => void;
  deleteTask: (task: PlanTask, taskGroup: PlanTaskGroup) => void;
  groupTask?: (task: PlanTask, taskGroup: PlanTaskGroup, inFilter: (taskGroup: PlanTaskGroup) => boolean) => void;
  ungroupTask?: (task: PlanTask, taskGroup: PlanTaskGroup) => void;
  autoAddModeGroup: string;
  setAutoAddModeGroup: (autoAddModeGroup: string) => void;
  autoAddTask: (group: PlanTaskGroup) => void;
}

interface Props extends TaskEditProps {
  task: PlanTask;
  taskGroup: PlanTaskGroup;
  roles: Dictionary<PlanRole>;
  rolesList: PlanRole[];
  shouldBlockUI: boolean;
  setEditingInProgress: (block: boolean) => void;
}

export const PlannedTask: React.FC<Props> = ({
  isEditingTaskId,
  setIsEditingTaskId,
  task,
  taskGroup,
  roles,
  rolesList,
  editTaskName,
  setTaskRole,
  toggleTaskCompletion,
  unplanTask,
  deleteTask,
  groupTask,
  ungroupTask,
  shouldBlockUI,
  setEditingInProgress,
  autoAddModeGroup,
  setAutoAddModeGroup,
  autoAddTask,
}) => {
  const [taskName, setTaskName] = React.useState(task.name);
  const [isRoleMenuOpen, setIsRoleMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isEditing = isEditingTaskId === task.id;

  const onEditStart = () => {
    setIsEditingTaskId(task.id, true);
    setEditingInProgress(true);
  };

  const onEditEnd = () => {
    setIsEditingTaskId(task.id, false);
    setEditingInProgress(false);
  };

  const onEditSaved = () => {
    onEditEnd();
    if (autoAddModeGroup !== "") {
      autoAddTask(taskGroup);
    }
  };

  const onEditCancelled = () => {
    if (autoAddModeGroup !== "") {
      setAutoAddModeGroup("");
      deleteTask(task, taskGroup);
    }
    onEditEnd();
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={{ minWidth: "575px" }}>
      <Checkbox
        aria-labelledby={`task-label-${task.id}`}
        data-testid="TaskCheckbox"
        checked={task.completed}
        sx={{
          [`&, &.${checkboxClasses.checked}`]: {
            color: colorForRole(roles, task.roleId),
          },
        }}
        onClick={() => {
          toggleTaskCompletion(task);
        }}
        disabled={shouldBlockUI}
      />
      <Tooltip enterDelay={1000} title={`${roles[task.roleId]?.name} task`} placement="top" arrow>
        <Box
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            setIsRoleMenuOpen(true);
          }}
          sx={{ p: "8px", backgroundColor: colorForRole(roles, task.roleId) }}
        />
      </Tooltip>
      <RoleMenu
        open={isRoleMenuOpen}
        anchorEl={anchorEl}
        onClose={() => setIsRoleMenuOpen(false)}
        roles={rolesList}
        selectedRoleIndex={rolesList.findIndex((role) => role.id === task.roleId)}
        setSelectedRole={(roleIndex: number) => {
          setAnchorEl(null);
          setIsRoleMenuOpen(false);
          setTaskRole(task, taskGroup, rolesList[roleIndex]);
        }}
      />
      {isEditing ? (
        <PlannedTaskTextField
          key={task.id}
          task={task}
          taskName={taskName}
          setTaskName={setTaskName}
          editTaskName={editTaskName}
          onEditSaved={onEditSaved}
          onEditCancelled={onEditCancelled}
        />
      ) : (
        <Stack direction="row" sx={{ alignItems: "center", flexGrow: 1 }}>
          <Typography
            id={`task-label-${task.id}`}
            sx={{
              p: 0,
              m: 0,
              flexGrow: 1,
              textDecoration: task.completed ? "line-through" : undefined,
              cursor: "text",
              overflowWrap: "anywhere",
            }}
            onClick={() => {
              if (!shouldBlockUI) {
                setTaskName(task.name);
                onEditStart();
              }
            }}
          >
            {task.name}
          </Typography>
          <Stack
            direction="row"
            sx={{
              my: "auto",
              flexGrow: 0,
            }}
          >
            {task.completed || shouldBlockUI ? (
              <></>
            ) : (
              <Tooltip title="Delete" placement="top">
                <IconButton
                  onClick={() => {
                    deleteTask(task, taskGroup);
                  }}
                  data-testid="DeleteTaskButton"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            {task.completed || shouldBlockUI || !ungroupTask ? (
              <></>
            ) : (
              <Tooltip title="Ungroup" placement="top">
                <IconButton
                  onClick={() => {
                    ungroupTask(task, taskGroup);
                  }}
                  size="small"
                >
                  <ExpandIcon />
                </IconButton>
              </Tooltip>
            )}
            {task.completed || shouldBlockUI ? (
              <></>
            ) : (
              <Tooltip title="Move to backlog" placement="top">
                <IconButton
                  onClick={() => {
                    unplanTask(task, taskGroup);
                  }}
                  data-testid="UnplanButton"
                  size="small"
                >
                  <ArrowOutwardIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
