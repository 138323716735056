import React from "react";
import { Dialog, Button, Stack, Typography, Checkbox, Box, DialogTitle } from "@mui/material";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { apiSetSettings } from "./settings.api";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectSettingsState } from "./settingsSlice";
import { TimeSelect } from "../../../components/timeSelect";
import { DEFAULT_MAX_TIME_BLOCK_COUNT } from "../../../util/modelTypes";

interface Props {
  open: boolean;
  onClose: () => void;
}

export const SettingsDialog: React.FC<Props> = ({ open, onClose }) => {
  const [dialogIsInitialized, setDialogIsInitialized] = React.useState(false);

  const [grayIsDefault, setGrayIsDefault] = React.useState(false);
  const [defaultStartTimeBlockNumber, setDefaultStartTimeBlockNumber] = React.useState(0);
  const [defaultEndTimeBlockNumber, setDefaultEndTimeBlockNumber] = React.useState(DEFAULT_MAX_TIME_BLOCK_COUNT - 1);

  const dispatch = useAppDispatch();

  const settingsState = useAppSelector(selectSettingsState);
  if (!settingsState.initialized) {
    return <></>;
  } else {
    if (!dialogIsInitialized) {
      setGrayIsDefault(settingsState.grayIsDefault);
      setDefaultStartTimeBlockNumber(settingsState.defaultStartTimeBlockNumber);
      setDefaultEndTimeBlockNumber(settingsState.defaultEndTimeBlockNumber);
      setDialogIsInitialized(true);
    }
  }

  const close = (client: ApolloClient<object>, save: boolean) => {
    if (save) {
      apiSetSettings(
        { client, settingsState, dispatch },
        grayIsDefault,
        defaultStartTimeBlockNumber,
        defaultEndTimeBlockNumber,
      );
    }
    onClose();
    setDialogIsInitialized(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Settings</DialogTitle>
      <Stack sx={{ p: 4 }} spacing={2}>
        <Stack direction="row" alignItems="center">
          <Checkbox
            checked={grayIsDefault}
            onChange={(_, checked) => {
              setGrayIsDefault(checked);
            }}
          />
          <Typography>Use gray time as the default</Typography>
        </Stack>
        <TimeSelect
          label="Default Start Time"
          timeBlock={defaultStartTimeBlockNumber}
          setTimeBlock={setDefaultStartTimeBlockNumber}
        />
        <TimeSelect
          label="Default End Time"
          isEndTime
          timeBlock={defaultEndTimeBlockNumber}
          setTimeBlock={setDefaultEndTimeBlockNumber}
        />
        <ApolloConsumer>
          {(client) => (
            <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }}>
              <Box sx={{ flexGrow: 1 }} />
              <Button onClick={() => close(client, false)}>Cancel</Button>
              <Button
                disabled={defaultStartTimeBlockNumber >= defaultEndTimeBlockNumber}
                variant="contained"
                onClick={() => close(client, true)}
              >
                Save
              </Button>
            </Stack>
          )}
        </ApolloConsumer>
      </Stack>
    </Dialog>
  );
};
