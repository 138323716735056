import React from "react";

import { Box, Button, Dialog, DialogTitle, Typography } from "@mui/material";
import { red } from "@mui/material/colors";

interface Props {
  title?: string;
  children: React.ReactNode;
  open: boolean;
  errorMessage: string;
  saveIsDisabled: () => boolean;
  cancel: () => void;
  saveAndClose: () => void;
  saveButtonLabel: string;
  extraButtons?: { label: string; onClick: () => void }[];
}

export const FormDialog: React.FC<Props> = ({
  children,
  title,
  open,
  errorMessage,
  saveIsDisabled,
  cancel,
  saveAndClose,
  saveButtonLabel,
  extraButtons,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        cancel();
      }}
      data-testid="Dialog"
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <Box flexDirection="column" sx={{ my: 1, mx: 1, display: "flex", gap: 2 }}>
        {children}
        {errorMessage === "" ? <React.Fragment /> : <Typography color={red[900]}>{errorMessage}</Typography>}
        <Box flexDirection="row" sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
          {extraButtons?.map((button) => (
            <Button
              key={button.label}
              data-testid={`${button.label}Button`}
              variant="outlined"
              onClick={button.onClick}
            >
              {button.label}
            </Button>
          ))}
          <Button
            data-testid="DialogCancelButton"
            variant="outlined"
            color="error"
            onClick={() => {
              cancel();
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid="DialogOkButton"
            variant="contained"
            color="primary"
            disabled={saveIsDisabled()}
            onClick={saveAndClose}
          >
            {saveButtonLabel}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
