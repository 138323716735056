import { useEffect, useState } from "react";

export const useVerticalScrollTracker = (jitterTime: number) => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    let intervalForScrollTracking: NodeJS.Timeout | null = null;

    const trackScrolling = () => {
      if (Math.abs(window.scrollY) !== scrollTop) {
        setScrollTop(window.scrollY);
      }
    };

    const startTrackScrolling = () => {
      if (!intervalForScrollTracking) {
        intervalForScrollTracking = setInterval(() => {
          trackScrolling();
        }, jitterTime);
      }
    };

    document.addEventListener("scroll", startTrackScrolling);

    return () => {
      document.removeEventListener("scroll", startTrackScrolling);
      if (intervalForScrollTracking) {
        clearInterval(intervalForScrollTracking);
      }
    };
  }, [scrollTop, setScrollTop, jitterTime]);

  return scrollTop;
};
