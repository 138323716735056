import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { PlanRole } from "../../../util/modelTypes";

interface Props {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  roles: PlanRole[];
  selectedRoleIndex: number;
  setSelectedRole: (roleIndex: number) => void;
}

export const RoleMenu: React.FC<Props> = ({ open, anchorEl, onClose, roles, selectedRoleIndex, setSelectedRole }) => {
  return (
    <Menu
      id="role-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": "role-button",
        role: "listbox",
      }}
    >
      {roles.map((option, index) => (
        <MenuItem key={option.id} selected={index === selectedRoleIndex} onClick={() => setSelectedRole(index)}>
          {option.name}
        </MenuItem>
      ))}
    </Menu>
  );
};
