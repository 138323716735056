import React from "react";
import { FormDialog } from "./formDialog";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { PlanProject } from "../../util/modelTypes";
import { uuidForId } from "../../util/uuidUtils";
import { isHexColor } from "../../util/colorUtils";
import { ColorField } from "../colorField";
import { DateFieldWithPicker } from "../dateFieldWithPicker";
import { compareDates, normalizeDateString, parseDateSimple } from "../../util/dateUtils";

interface Props {
  title: string;
  open: boolean;
  saveAndClose: (project: PlanProject) => void;
  cancel: () => void;
  project: PlanProject;
}

export const EditProjectDialog: React.FC<Props> = ({ title, open, saveAndClose, cancel, project }) => {
  const [initialized, setInitialized] = React.useState(false);
  const [currentProject, setCurrentProject] = React.useState(project);

  if (!initialized && open) {
    if (project.id === "") {
      setCurrentProject({ ...project, id: uuidForId() });
    } else {
      setCurrentProject(project);
    }
    setInitialized(true);
    return <></>;
  } else if (initialized && !open) {
    setInitialized(false);
    return <></>;
  }

  return (
    <FormDialog
      open={open}
      title={title}
      errorMessage=""
      saveIsDisabled={() => {
        const startDate = parseDateSimple(currentProject.startDate);
        const endDate = parseDateSimple(currentProject.endDate);
        return (
          currentProject.name === "" ||
          !isHexColor(currentProject.color) ||
          startDate === undefined ||
          endDate === undefined ||
          compareDates(startDate, endDate) >= 0 ||
          currentProject.weeklyPaceTimeBlockCount < 0
        );
      }}
      cancel={cancel}
      saveAndClose={() => {
        const normalizedStartDate = normalizeDateString(currentProject.startDate);
        const normalizedEndDate = normalizeDateString(currentProject.endDate);

        // This is mostly to satify the type checker.
        // The Save button should be disabled if the dates are invalid.
        if (normalizedStartDate && normalizedEndDate) {
          const normalizedProject = {
            ...currentProject,
            startDate: normalizedStartDate,
            endDate: normalizedEndDate,
          };
          saveAndClose(normalizedProject);
        }
      }}
      saveButtonLabel="Save Project"
    >
      <Stack spacing={2} sx={{ width: "450px" }}>
        <TextField
          autoFocus={true}
          required={true}
          error={currentProject.name === ""}
          label="Name"
          value={currentProject.name}
          variant="outlined"
          sx={{ minWidth: 350 }}
          color="primary"
          onChange={(e) => setCurrentProject({ ...currentProject, name: e.target.value })}
          onKeyUp={(e) => {
            if (e.key === "Escape") {
              cancel();
            }
          }}
        />
        <ColorField
          color={currentProject.color}
          setColor={(color: string) => {
            setCurrentProject({ ...currentProject, color: color });
          }}
          onKeyUp={(e) => {
            if (e.key === "Escape") {
              cancel();
            }
          }}
          required={true}
        />

        <DateFieldWithPicker
          label="Start date"
          dateString={currentProject.startDate}
          setDateString={(dateString: string) => {
            setCurrentProject({ ...currentProject, startDate: dateString });
          }}
          required={true}
        />
        <DateFieldWithPicker
          label="End date"
          dateString={currentProject.endDate}
          setDateString={(dateString: string) => {
            setCurrentProject({ ...currentProject, endDate: dateString });
          }}
          required={true}
        />
        <Stack direction="row" alignItems="center" spacing={2}>
          <Stack direction="row" alignItems="center">
            <Checkbox
              checked={currentProject.showOnScoreboard}
              onChange={(_, checked) => {
                setCurrentProject({ ...currentProject, showOnScoreboard: checked });
              }}
            />
            <Typography>Show score</Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <FormControl sx={{ flexGrow: 1 }} disabled={!currentProject.showOnScoreboard}>
            <InputLabel id={`day-select-label`}>Week starts on</InputLabel>
            <Select
              labelId={`day-select-label`}
              id={`day-select`}
              value={currentProject.weekStartsDayOfWeek.toString()}
              label="Week starts on"
              onChange={(event: SelectChangeEvent) => {
                setCurrentProject({ ...currentProject, weekStartsDayOfWeek: Number(event.target.value) });
              }}
            >
              <MenuItem value="0">Sunday</MenuItem>
              <MenuItem value="1">Monday</MenuItem>
            </Select>
          </FormControl>
          <TextField
            required={true}
            label="Weekly pace timeblocks"
            type="number"
            error={currentProject.weeklyPaceTimeBlockCount < 0}
            value={currentProject.weeklyPaceTimeBlockCount}
            variant="outlined"
            color="primary"
            sx={{ flexGrow: 0 }}
            disabled={!currentProject.showOnScoreboard}
            onChange={(e) =>
              setCurrentProject({ ...currentProject, weeklyPaceTimeBlockCount: parseInt(e.target.value) })
            }
            onKeyUp={(e) => {
              if (e.key === "Escape") {
                cancel();
              }
            }}
          />
        </Stack>
      </Stack>
    </FormDialog>
  );
};
