import { TodayPageQuery } from "../../../../generated/graphql";
import { ROLE_BLOCK_BREAK } from "../../../../util/modelTypes";
import { TodayPageState } from "../../todayPageSlice";

export function verifyDataMatchesClient(data: TodayPageQuery["userDailyPlanForDate"], todayPageState: TodayPageState) {
  if (!data || !data.taskGroups) {
    throw new Error("No data");
  }
  if (data.taskGroups.length !== todayPageState.taskGroups.length) {
    throw new Error("Number of groups doesn't match");
  }
  if (data.startTimeBlockNumber !== todayPageState.startTimeBlockNumber) {
    throw new Error("Start time block numbers don't match");
  }
  if (data.endTimeBlockNumber !== todayPageState.endTimeBlockNumber) {
    throw new Error("End time block numbers don't match");
  }
  for (let i = 0; i < data.taskGroups.length; i++) {
    if (data.taskGroups[i].id !== todayPageState.taskGroups[i].id) {
      throw new Error(`Group[${i}] ids don't match`);
    }
    if (data.taskGroups[i].name !== todayPageState.taskGroups[i].name) {
      throw new Error(`Group[${i}] name don't match`);
    }
    if (data.taskGroups[i].order !== todayPageState.taskGroups[i].order) {
      throw new Error(`Group[${i}] orders don't match`);
    }
    if (data.taskGroups[i].timeBlockCount !== todayPageState.taskGroups[i].timeBlockCount) {
      throw new Error(`Group[${i}] time block counts don't match`);
    }
    if (data.taskGroups[i].completedTimeBlockCount !== todayPageState.taskGroups[i].completedTimeBlockCount) {
      throw new Error(`Group[${i}] completed time block counts don't match`);
    }
    if (data.taskGroups[i].collapsed !== todayPageState.taskGroups[i].collapsed) {
      throw new Error(`Group[${i}] collapsed don't match`);
    }
    if (data.taskGroups[i].routine?.id !== todayPageState.taskGroups[i].routineId) {
      throw new Error(`Group[${i}] routines don't match`);
    }
    if (data.taskGroups[i].project?.id !== todayPageState.taskGroups[i].projectId) {
      throw new Error(`Group[${i}] projects don't match`);
    }
    if (data.taskGroups[i].isGrayTime !== todayPageState.taskGroups[i].isGrayTime) {
      throw new Error(`Group[${i}] isGrayTime don't match`);
    }
    if (data.taskGroups[i].tasks.length !== todayPageState.taskGroups[i].tasks.length) {
      throw new Error(`Group[${i}] Number of tasks doesn't match`);
    }
    for (let t = 0; t < data.taskGroups[i].tasks.length; t++) {
      if (data.taskGroups[i].tasks[t].id !== todayPageState.taskGroups[i].tasks[t].id) {
        throw new Error(`Group[${i}] Task[${t}] ids don't match`);
      }
      if (data.taskGroups[i].tasks[t].name !== todayPageState.taskGroups[i].tasks[t].name) {
        throw new Error(`Group[${i}] Task[${t}] names don't match`);
      }
      if (data.taskGroups[i].tasks[t].completed !== todayPageState.taskGroups[i].tasks[t].completed) {
        throw new Error(`Group[${i}] Task[${t}] completed states don't match`);
      }
      if (data.taskGroups[i].tasks[t].order !== todayPageState.taskGroups[i].tasks[t].order) {
        throw new Error(`Group[${i}] Task[${t}] orders don't match`);
      }
      if (data.taskGroups[i].tasks[t].role.id !== todayPageState.taskGroups[i].tasks[t].roleId) {
        throw new Error(`Group[${i}] Task[${t}] role ids don't match`);
      }
    }
    if (todayPageState.plannedBlocks.filter((pb) => pb !== ROLE_BLOCK_BREAK).length !== data.plannedBlocks.length) {
      throw new Error(`Guide rail block counts don't match: Server[${data.plannedBlocks.length}]`);
    }
    for (let pb = 0; pb < data.plannedBlocks.length; pb++) {
      const clientPb = todayPageState.plannedBlocks[data.plannedBlocks[pb].blockNumber];
      if (clientPb.blockType !== data.plannedBlocks[pb].type) {
        throw new Error(`Planned block [${data.plannedBlocks[pb].blockNumber}] block types don't match`);
      }
      if (clientPb.role?.id !== data.plannedBlocks[pb].role?.id) {
        throw new Error(`Planned block [${data.plannedBlocks[pb].blockNumber}] roles don't match`);
      }
    }
  }
}
