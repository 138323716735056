import React, { useEffect } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { DashboardRoleNumbers } from "./dashboardRoleNumbers";
import { DashboardTimer } from "./dashboardTimer";
import { TimerState } from "./utils/timerState";
import { TimerType } from "./utils/timerType";
import { PlanAndCompletedBlockCountResponse } from "../../../generated/graphql";
import { DashboardWeekTimeBlockChart } from "./dashboardWeekTimeBlockChart";
import { Dictionary } from "@reduxjs/toolkit";
import { PlanRole } from "../../../util/modelTypes";
import { colorForRole } from "./todayPageStateUtils";
import { timerEffectFn } from "./utils/timerEffect";

interface Props {
  date: Date;
  compact: boolean;
  setCompact: (compact: boolean) => void;
  selectedRoles: Dictionary<PlanRole>;
  secondsLeftOnTimer: number;
  totalTimeForTimer: number;
  timerState: TimerState;
  timerType: TimerType;
  setTimerType: (timerType: TimerType) => void;
  timerRoleId: string;
  setTimerRoleId: (roleId: string) => void;
  roles: Dictionary<PlanRole>;
  rolesList: PlanRole[];
  roleBlockCounts: Dictionary<number>;
  completedTimeBlockCount: (role: PlanRole) => number;
  grayCompletedTimeBlockCount: (role: PlanRole) => number;
  weekTimeBlocks: PlanAndCompletedBlockCountResponse[];
  blocksLeftForRole: (time: Date, role: PlanRole) => number;
  blocksPastForRole: (time: Date, role: PlanRole) => number;
  blocksLeftForRoleInTasks: (role: PlanRole) => number;
  navigateToDaysAgo: (daysAgo: number) => void;
  startTimer: () => void;
  pauseTimer: () => void;
  resetTimer: () => void;
  endTimer: () => void;
}

export const PlanPageDashboard: React.FC<Props> = ({
  date,
  compact,
  setCompact,
  selectedRoles,
  secondsLeftOnTimer,
  totalTimeForTimer,
  timerState,
  timerType,
  setTimerType,
  timerRoleId,
  setTimerRoleId,
  roleBlockCounts,
  roles,
  rolesList,
  completedTimeBlockCount,
  grayCompletedTimeBlockCount,
  weekTimeBlocks,
  blocksLeftForRole,
  blocksPastForRole,
  blocksLeftForRoleInTasks,
  navigateToDaysAgo,
  startTimer,
  pauseTimer,
  resetTimer,
  endTimer,
}) => {
  const [time, setTime] = React.useState<Date>(new Date());
  useEffect(() => {
    return timerEffectFn(true, 30000, setTime);
  }, [setTime]);

  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        my: 1,
        borderRadius: 5,
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
        backgroundColor: "white",
        py: compact ? 0.5 : 2,
        pr: 2,
        pl: 6,
        position: "relative",
      }}
      spacing={1}
      data-testid="TodayDashboard"
    >
      <DashboardTimer
        compact={compact}
        secondsLeftOnTimer={secondsLeftOnTimer}
        totalTimeForTimer={totalTimeForTimer}
        timerState={timerState}
        timerType={timerType}
        roles={roles}
        rolesList={rolesList}
        roleId={timerRoleId}
        setRoleId={setTimerRoleId}
        setTimerType={setTimerType}
        startTimer={startTimer}
        pauseTimer={pauseTimer}
        resetTimer={resetTimer}
        endTimer={endTimer}
      />
      {Object.values(selectedRoles).length === 0 ? (
        <></>
      ) : (
        <Stack direction="row" spacing={2} sx={{ flexGrow: 0, flexShrink: 1, justifySelf: "center" }}>
          <Stack spacing={1} sx={{ flexGrow: 1, flexShrink: 1 }}>
            {compact ? (
              <></>
            ) : (
              <Typography fontSize="small" align="center">
                Balance
              </Typography>
            )}
            {rolesList
              .filter((role) => selectedRoles[role.id])
              .map((role) => (
                <DashboardRoleNumbers
                  compact={compact}
                  key={role.id}
                  numbers={[blocksLeftForRole(time, role) - blocksLeftForRoleInTasks(role)]}
                  color={colorForRole(roles, role.id)}
                  highlightColor="#DF5536"
                  highlight={blocksLeftForRole(time, role) - blocksLeftForRoleInTasks(role) < 0}
                  tooltip={((gr: number, tg: number): string => {
                    return `There ${gr === 1 ? "is" : "are"} ${gr} ${role.name} block${
                      gr === 1 ? "" : "s"
                    } left, and ${tg} ${tg === 1 ? "is" : "are"} planned`;
                  })(blocksLeftForRole(time, role), blocksLeftForRoleInTasks(role))}
                />
              ))}
          </Stack>
          <Stack spacing={1} sx={{ flexGrow: 1, flexShrink: 1 }}>
            {compact ? (
              <></>
            ) : (
              <Typography fontSize="small" align="center">
                Pace&nbsp;|&nbsp;Complete
              </Typography>
            )}
            {rolesList
              .filter((role) => selectedRoles[role.id])
              .map((role) => (
                <DashboardRoleNumbers
                  compact={compact}
                  key={role.id}
                  numbers={[blocksPastForRole(time, role), completedTimeBlockCount(role)]}
                  color={colorForRole(roles, role.id)}
                  highlightColor="#DF5536"
                  highlight={blocksPastForRole(time, role) > completedTimeBlockCount(role)}
                  tooltip={((gr: number, tg: number): string => {
                    return `${gr} ${role.name} block${gr === 1 ? " has" : "s have"} passed, and ${tg} ${
                      tg === 1 ? "is" : "are"
                    }  completed`;
                  })(blocksPastForRole(time, role), completedTimeBlockCount(role))}
                />
              ))}
          </Stack>
        </Stack>
      )}
      {rolesList
        .filter((role) => selectedRoles[role.id])
        .map((role) => (
          <Stack key={role.id} sx={{ flexGrow: 0, justifyContent: "flex-end", alignItems: "center" }}>
            <DashboardWeekTimeBlockChart
              date={date}
              compact={compact}
              maxTimeBlockCount={roleBlockCounts[role.id] ?? 0}
              color={colorForRole(roles, role.id)}
              completedTimeBlockCount={completedTimeBlockCount(role)}
              grayCompletedTimeBlockCount={grayCompletedTimeBlockCount(role)}
              weekTimeBlocks={weekTimeBlocks.filter((weekTimeBlock) => weekTimeBlock.roleId === role.id)}
              navigateToDaysAgo={navigateToDaysAgo}
            />
          </Stack>
        ))}
      {/* Put a button to set compact state in the upper right */}
      <Stack
        sx={{
          position: "absolute",

          left: 0,
          zIndex: 10,
          borderRadius: 1,
        }}
      >
        <IconButton onClick={() => setCompact(!compact)}>
          {compact ? <UnfoldMoreIcon fontSize="small" /> : <UnfoldLessIcon fontSize="small" />}
        </IconButton>
      </Stack>
    </Stack>
  );
};
