import React, { useState } from "react";
import { FormDialog } from "./formDialog";
import { TimeSelect } from "../timeSelect";

interface Props {
  title: string;
  currentTimeBlock?: number;
  buttonLabel: string;
  open: boolean;
  onClose: () => void;
  onSave: (timeBlock?: number) => Promise<void>;
}

export const PromptForTimeBlockDialog: React.FC<Props> = ({
  title,
  buttonLabel,
  currentTimeBlock,
  open,
  onClose,
  onSave,
}) => {
  const [startTimeBlockNumber, setStartTimeBlockNumber] = useState(currentTimeBlock ?? 24);
  return (
    <FormDialog
      title={title}
      open={open}
      errorMessage={""}
      saveIsDisabled={function (): boolean {
        return false;
      }}
      cancel={onClose}
      saveAndClose={() => {
        onSave(startTimeBlockNumber);
      }}
      saveButtonLabel={buttonLabel}
      extraButtons={
        currentTimeBlock
          ? [
              {
                label: "Clear Start Time",
                onClick: () => {
                  onSave(undefined);
                },
              },
            ]
          : undefined
      }
    >
      <TimeSelect label="Start Time" timeBlock={startTimeBlockNumber} setTimeBlock={setStartTimeBlockNumber} />
    </FormDialog>
  );
};
