import React from "react";
import { DropResult } from "react-beautiful-dnd";
import { PlanRole, PlanTaskGroup } from "../../../util/modelTypes";
import { Dictionary } from "@reduxjs/toolkit";
import { TaskGroupsWithRolesInterspersed } from "./taskGroupsWithRolesInterspersed";
import { TaskGroupsWithRolesSequential } from "./taskGroupsWithRolesSequential";
import { allTasksCompletedInTaskGroup, allTasksInSelectedRoles } from "../today/todayPageStateUtils";
import { Typography } from "@mui/material";
import { TaskGroupLabel } from "./draggableTaskGroup";
import { TaskGroupMenuProps } from "./taskGroupActionMenu";
import { TaskEditProps } from "./plannedTask";
import { TaskGroupHeaderProps } from "./draggableTaskGroupHeader";

interface Props extends TaskGroupMenuProps, TaskEditProps, TaskGroupHeaderProps {
  taskGroups: PlanTaskGroup[];
  dropEnd: (result: DropResult) => void;
  roles: Dictionary<PlanRole>;
  rolesList: PlanRole[];
  selectedRoles: Dictionary<PlanRole>;
  roleView: string;
  roleViewCompleted: string;
  editingInProgress: boolean;
  toggleTaskGroupCollapsed: (task: PlanTaskGroup) => void;
  shouldBlockUI: boolean;
  setEditingInProgress: (block: boolean) => void;
  onTimeBlockClick: (taskGroup: PlanTaskGroup, timeBlockIndex: number) => void;
  onCompletedTimeBlockClick: (taskGroup: PlanTaskGroup, timeBlockIndex: number) => void;
  colorForRole: (roleId: string) => string;
  canBeGrouped: (index: number, taskGroup: PlanTaskGroup, inFilter: (taskGroup: PlanTaskGroup) => boolean) => boolean;
  labelsForTaskGroup: (taskGroup: PlanTaskGroup) => TaskGroupLabel[];
  selectedTaskGroupId?: string;
  onSelectTaskGroup: (taskGroupId: string) => void;
}

export const PlannedTaskGroups: React.FC<Props> = (props: Props) => {
  const { roleView, roleViewCompleted, selectedRoles, labelsForTaskGroup } = props;
  return (
    <div data-testid="Plan">
      {Object.values(selectedRoles).length === 0 ? (
        <Typography>
          <em>No roles selected</em>
        </Typography>
      ) : (
        <React.Fragment>
          {roleView === "Interspersed" ? (
            <TaskGroupsWithRolesInterspersed
              {...props}
              droppableId={`droppable-id`}
              inFilter={(taskGroup) =>
                allTasksInSelectedRoles(taskGroup, selectedRoles) &&
                (roleViewCompleted !== "Hide" || !allTasksCompletedInTaskGroup(taskGroup))
              }
              labelsForTaskGroup={labelsForTaskGroup}
            />
          ) : (
            <TaskGroupsWithRolesSequential {...props} />
          )}
        </React.Fragment>
      )}
    </div>
  );
};
