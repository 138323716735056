import React from "react";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Button, Stack, Tooltip } from "@mui/material";
import { planTasksToolbarButtonDefaultStyle } from "./planTasksToolbar";

interface Props {
  setAllTaskgroupsCollapsed: (collapsed: boolean) => void;
}

export const PlanCollapser: React.FC<Props> = ({ setAllTaskgroupsCollapsed }) => {
  return (
    <Stack direction="row" spacing={0.25}>
      <Tooltip title="Collapse All">
        <Button
          onClick={() => setAllTaskgroupsCollapsed(true)}
          sx={{ ...planTasksToolbarButtonDefaultStyle }}
          variant="outlined"
        >
          <UnfoldLessIcon />
        </Button>
      </Tooltip>
      <Tooltip title="Expand All">
        <Button
          onClick={() => setAllTaskgroupsCollapsed(false)}
          sx={{ ...planTasksToolbarButtonDefaultStyle }}
          variant="outlined"
        >
          <UnfoldMoreIcon />
        </Button>
      </Tooltip>
    </Stack>
  );
};
