import React from "react";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";

interface Props {
  roleView: string;
  setRoleView: (v: string) => void;
}

export const RoleViewSelector: React.FC<Props> = ({ roleView, setRoleView }) => {
  const handleViewChange = (event: React.MouseEvent<HTMLElement>, newRoleView: string) => {
    if (newRoleView === null) return;
    setRoleView(newRoleView);
  };

  return (
    <ToggleButtonGroup exclusive value={roleView} onChange={handleViewChange} aria-label="role view setting">
      <ToggleButton value="Interspersed" aria-label="Interspersed">
        <Tooltip title="Show Roles Interspersed">
          <FormatAlignCenterIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="Sequential" aria-label="Sequential">
        <Tooltip title="Show Roles Sequentially">
          <ViewAgendaIcon />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
