import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import { setAccessToken } from "../util/auth";
import { SettingsDialog } from "../pages/userApp/settings/settingsDialog";

export const UserAppBar: React.FC = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const open = Boolean(anchorEl);

  const [settingsOpen, setSettingsOpen] = useState(false);

  const handleAvatarClick = (target: EventTarget & HTMLButtonElement) => {
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setAccessToken("");
    fetch("/logout", { method: "POST", credentials: "include" }).then(() => {
      navigate("/login");
    });
  };

  const changePassword = () => {
    navigate("/change-password");
  };

  const settings = () => {
    setSettingsOpen(true);
    handleClose();
  };

  return (
    <Stack direction="row" justifyContent="flex-end">
      <Tooltip title="Account settings">
        <IconButton
          onClick={(e) => {
            handleAvatarClick(e.currentTarget);
          }}
        >
          <AccountCircleIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={settings}>Settings</MenuItem>
        <MenuItem onClick={changePassword}>Change Password</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
      <SettingsDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
        }}
      />
    </Stack>
  );
};
