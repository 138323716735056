import React, { useState } from "react";

import { TextField } from "@mui/material";
import { FormDialog } from "./formDialog";

interface Props {
  open: boolean;
  onClose: () => void;
  label: string;
  buttonLabel: string;
  onSave: (text: string, onSuccess: () => void, onError: (msg: string) => void) => Promise<void>;
  defaultText?: string;
}

export const PromptForTextDialog: React.FC<Props> = ({ open, onClose, label, buttonLabel, onSave, defaultText }) => {
  const [initialized, setInitialized] = useState(false);
  const [text, setText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  if (!initialized && open) {
    if (defaultText) {
      setText(defaultText);
    }
    setInitialized(true);
    return <></>;
  }

  const saveIsDisabled = () => {
    return text === "";
  };

  const closeDialog = () => {
    setErrorMessage("");
    setText("");
    setInitialized(false);
    onClose();
  };

  const saveAndCloseDialog = async () => {
    setErrorMessage("");
    await onSave(
      text,
      () => {
        closeDialog();
      },
      (msg) => {
        setErrorMessage(msg);
        return msg;
      },
    );
  };

  return (
    <FormDialog
      open={open}
      cancel={closeDialog}
      errorMessage={errorMessage}
      saveAndClose={saveAndCloseDialog}
      saveIsDisabled={saveIsDisabled}
      saveButtonLabel={buttonLabel}
    >
      <TextField
        autoFocus
        onChange={(e) => {
          setText(e.target.value);
        }}
        value={text}
        label={label}
        variant="outlined"
        sx={{ minWidth: 350 }}
        required
        color="primary"
        onFocus={(event) => {
          event.target.select();
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            saveAndCloseDialog();
          } else if (e.key === "Escape") {
            closeDialog();
          }
        }}
      />
    </FormDialog>
  );
};
