import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AlertColor } from "@mui/material";
import { RootState } from "../store/store";

/**
 * Defines the slice, reducers, and actions for putting up a snackbar.
 */

export interface SnackbarState {
  open: boolean;
  text: string;
  severity: AlertColor;
}

const initialState: SnackbarState = {
  open: false,
  text: "",
  severity: "success",
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    clearSnackbarState: (state) => {
      state.open = false;
      state.text = "";
      state.severity = "success";
    },
    showSnackbarSuccess: (state, action: PayloadAction<string>) => {
      state.open = true;
      state.text = action.payload;
      state.severity = "success";
    },
    showSnackbarError: (state, action: PayloadAction<string>) => {
      state.open = true;
      state.text = action.payload;
      state.severity = "error";
    },
    closeSnackbar: (state) => {
      state.open = false;
    },
  },
});

export const { clearSnackbarState, showSnackbarSuccess, showSnackbarError, closeSnackbar } = snackbarSlice.actions;

export const selectSnackbar = (state: RootState) => state.snackbar as SnackbarState;

export default snackbarSlice.reducer;
