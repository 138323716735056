import { ApolloClient } from "@apollo/client";
import {
  SettingsState,
  setSettingsDefaultEndTimeBlockNumber,
  setSettingsDefaultStartTimeBlockNumber,
  setSettingsGrayIsDefault,
} from "./settingsSlice";
import { AppDispatch } from "../../../store/store";
import { optimisticUpdateAndServerRequestWithUndo } from "../today/api/api.utils";
import { requestSetUserSettings } from "../../../generated/graphqlWrappers";

export interface SettingsOptimisticAPIContext {
  client: ApolloClient<object>;
  settingsState: SettingsState;
  dispatch: AppDispatch;
}

export const apiSetSettings = (
  apiContext: SettingsOptimisticAPIContext,
  grayIsDefault: boolean,
  defaultStartTimeBlockNumber: number,
  defaultEndTimeBlockNumber: number,
) => {
  const { client, settingsState, dispatch } = apiContext;

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: [
      setSettingsGrayIsDefault({ grayIsDefault }),
      setSettingsDefaultStartTimeBlockNumber({ defaultStartTimeBlockNumber }),
      setSettingsDefaultEndTimeBlockNumber({ defaultEndTimeBlockNumber }),
    ],
    request: requestSetUserSettings,
    variables: { grayIsDefault, defaultStartTimeBlockNumber, defaultEndTimeBlockNumber },
    undo: [
      setSettingsGrayIsDefault({ grayIsDefault: settingsState.grayIsDefault }),
      setSettingsDefaultStartTimeBlockNumber({
        defaultStartTimeBlockNumber: settingsState.defaultStartTimeBlockNumber,
      }),
      setSettingsDefaultEndTimeBlockNumber({ defaultEndTimeBlockNumber: settingsState.defaultEndTimeBlockNumber }),
    ],
  });
};
