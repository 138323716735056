import React from "react";
import { List, ListItem, ListItemIcon, ListItemText, Skeleton, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { isSiteAdmin } from "../util/auth";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import InsightsIcon from "@mui/icons-material/Insights";

interface Nav {
  text: string;
  url?: string;
  icon?: React.ReactNode;
}

interface ItemProps {
  chosen?: string;
  nav: Nav;
}

export const NavItem: React.FC<ItemProps> = ({ chosen, nav }) => {
  const color = chosen === nav.text ? "#3874CB" : "#666";
  return (
    <ListItem selected={chosen === nav.text} sx={{ "&:hover": { backgroundColor: nav.url ? "#f8f8f8" : "inherit" } }}>
      {nav.icon ? <ListItemIcon sx={{ color }}>{nav.icon}</ListItemIcon> : <React.Fragment />}
      {nav.url ? (
        <Link to={nav.url} style={{ textDecoration: "none", color }}>
          <ListItemText sx={{ mx: -2 }} primary={nav.text} />
        </Link>
      ) : (
        <ListItemText
          primary={nav.text}
          sx={{ mx: -1 }}
          primaryTypographyProps={{ color: "#666", fontWeight: "bold" }}
        />
      )}
    </ListItem>
  );
};

interface Props {
  chosen?: string;
  title?: string;
  sidebar: Nav[];
}

export const NavSidebar: React.FC<Props> = ({ chosen, title, sidebar }) => {
  return (
    <Stack>
      {title !== undefined ? (
        title !== "" ? (
          <Typography sx={{ ml: 1, color: "#666" }} fontWeight="bold">
            {title}
          </Typography>
        ) : (
          <Typography>
            <Skeleton variant="text" />
          </Typography>
        )
      ) : (
        <React.Fragment />
      )}
      <List dense={true}>
        {sidebar.map((nav) => (
          <NavItem key={nav.text} chosen={chosen} nav={nav} />
        ))}
        {isSiteAdmin() ? (
          <React.Fragment>
            <NavItem chosen={chosen} nav={{ text: "Site Admin" }} />
            <NavItem chosen={chosen} nav={{ text: "Invite", url: "/admin/invite-user", icon: <ContactMailIcon /> }} />
            <NavItem
              chosen={chosen}
              nav={{ text: "Invitations", url: "/admin/invitations", icon: <ForwardToInboxIcon /> }}
            />
            <NavItem chosen={chosen} nav={{ text: "Stats", url: "/admin/stats", icon: <InsightsIcon /> }} />
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
      </List>
    </Stack>
  );
};
