import React from "react";
import { PlanTaskGroup } from "../../util/modelTypes";
import { TimeBlockButtons } from "./timeBlockButtons";

interface Props {
  taskGroup: PlanTaskGroup;
  onTimeBlockClick: (taskGroup: PlanTaskGroup, timeBlockIndex: number) => void;
  onCompletedTimeBlockClick?: (taskGroup: PlanTaskGroup, timeBlockIndex: number) => void;
  colorForRole: (roleId: string) => string;
}

export const PlanTimeBlockButtons: React.FC<Props> = ({
  taskGroup,
  onTimeBlockClick,
  onCompletedTimeBlockClick,
  colorForRole,
}) => {
  const timeBlocksToRender = Math.max(
    5,
    Math.ceil(Math.max(taskGroup.completedTimeBlockCount, taskGroup.timeBlockCount) / 2) * 2 + 1,
  );

  const allTasksCompleted = taskGroup.tasks.every((task) => task.completed);

  return (
    <TimeBlockButtons
      timeBlockCount={taskGroup.timeBlockCount}
      isGray={taskGroup.isGrayTime}
      completedTimeBlockCount={taskGroup.completedTimeBlockCount}
      timeBlocksToRender={timeBlocksToRender}
      timeBlockOpacity={(timeBlockIndex: number) => {
        return allTasksCompleted &&
          timeBlockIndex < taskGroup.timeBlockCount &&
          timeBlockIndex >= taskGroup.completedTimeBlockCount
          ? 0.5
          : 1;
      }}
      onTimeBlockClick={(timeBlockIndex: number) => {
        onTimeBlockClick(taskGroup, timeBlockIndex);
      }}
      onCompletedTimeBlockClick={
        onCompletedTimeBlockClick
          ? (timeBlockIndex: number) => {
              onCompletedTimeBlockClick(taskGroup, timeBlockIndex);
            }
          : undefined
      }
      colorForRole={() => {
        return colorForRole(taskGroup.tasks[0].roleId);
      }}
    />
  );
};
