import React from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import MenuIcon from "@mui/icons-material/Menu";
import { DateNavigation } from "./dateNavigation";
import { UserAppBar } from "../../../components/userAppBar";

interface Props {
  currentDate: Date;
  appBarHidden: boolean;
  sidebarDrawerIsOpen: boolean;
  openSidebarDrawer: () => void;
  openBacklogDrawer: () => void;
  navigateToDate: (date: Date) => void;
}

export const PlanPageHeaderNav: React.FC<Props> = ({
  currentDate,
  appBarHidden,
  sidebarDrawerIsOpen,
  openSidebarDrawer,
  openBacklogDrawer,
  navigateToDate,
}) => {
  return (
    <Stack direction="row" sx={{ justifyContent: "space-between" }}>
      <Stack direction="row" visibility={appBarHidden && !sidebarDrawerIsOpen ? "visible" : "hidden"}>
        <Tooltip title="Sidebar">
          <IconButton onClick={openSidebarDrawer} sx={{ alignSelf: "center" }}>
            <MenuIcon />
          </IconButton>
        </Tooltip>
      </Stack>

      <DateNavigation date={currentDate} setDate={navigateToDate} />

      <Stack direction="row" visibility={appBarHidden ? "visible" : "hidden"}>
        <UserAppBar />
        <Tooltip title="Backlog">
          <IconButton onClick={openBacklogDrawer} sx={{ alignSelf: "center" }}>
            <ViewListIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
