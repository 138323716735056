import dayjs from "dayjs";

export const MINUTES_IN_A_TIMEBLOCK = 30;
export const SECONDS_IN_A_TIMEBLOCK = 60 * MINUTES_IN_A_TIMEBLOCK;
export const MILLISECONDS_IN_A_WEEK = 7 * 24 * 60 * 60 * 1000;

export const compareDates = (date1: Date, date2: Date) => {
  if (date1.getFullYear() !== date2.getFullYear()) {
    return date1.getFullYear() - date2.getFullYear();
  } else if (date1.getMonth() !== date2.getMonth()) {
    return date1.getMonth() - date2.getMonth();
  } else {
    return date1.getDate() - date2.getDate();
  }
};

export const formatDateSimple = (date: Date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

export const parseDateSimple = (dateString: string): Date | undefined => {
  if (!dateString.match(/^\d{4}-\d{1,2}-\d{1,2}$/)) {
    return undefined;
  }
  try {
    return dayjs(dateString).toDate();
  } catch {
    return undefined;
  }
};

export const normalizeDateString = (dateString: string) => {
  const date = parseDateSimple(dateString);
  if (date) {
    return formatDateSimple(date);
  }
  return undefined;
};

export const formatDateTimeSimple = (date: Date) => {
  return formatDateSimple(date) + " " + date.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" });
};

export const formatSecondsTimeSimple = (seconds: number) => {
  let date = new Date(formatDateSimple(new Date()));
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(seconds);
  return date.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" });
};

export const formatDateOnly = (date: Date) => {
  return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
};

export const formatSecondsAsTime = (seconds: number) => {
  let date = new Date(0);
  date.setSeconds(seconds);
  return date.toISOString().substring(11, 19);
};

export const formatSecondsAsTimeCompact = (seconds: number) => {
  try {
    var time = formatSecondsAsTime(seconds);
    if (!time.startsWith("00:")) {
      return time;
    }
    time = time.substring(3);
    if (!time.startsWith("0")) {
      return time;
    }
    time = time.substring(1);
    return time;
  } catch {
    return `unknown time`;
  }
};

export const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const daysOfTheWeekAbbreviations = dayNames.map((day) => day.substring(0, 1));

export const dayName = (date: Date) => {
  return dayNames[date.getDay()];
};

export const daysInWeekToDate = (date: Date) => {
  const dayOfWeek = date.getDay();

  const twoWeeks = [...daysOfTheWeekAbbreviations, ...daysOfTheWeekAbbreviations];
  return [
    ...twoWeeks.slice(dayOfWeek, dayOfWeek + (7 - dayOfWeek)),
    ...daysOfTheWeekAbbreviations.slice(0, dayOfWeek + 1),
  ];
};

export const dateAddingDays = (date: Date, days: number) => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
};
