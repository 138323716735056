import React, { useEffect } from "react";
import { useRoutinesQuery } from "../../generated/graphql";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { clearRoutinesState, initializeRoutinesState, selectRoutinesState } from "../../pages/userApp/routinesSlice";

export const RoutinesStateLoader: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data, loading, error } = useRoutinesQuery({
    variables: {},
    fetchPolicy: "network-only",
  });
  const routinesState = useAppSelector(selectRoutinesState);

  // You need an effect to deal with loading state because you want to change state
  // via a dispatch while loading. You can't do that in the render function.
  useEffect(() => {
    if (loading) {
      dispatch(clearRoutinesState());
    } else if (data && !routinesState.initialized) {
      dispatch(initializeRoutinesState({ data, selectedRoleIds: undefined }));
    }
  });

  if (loading) {
    return <></>;
  } else if (error) {
    return <></>;
  } else if (!data) {
    return <></>;
  } else if (!routinesState.initialized) {
    return <></>;
  }

  return <></>;
};
