/**
 * Never store the access token in anything other than a TS variable. Never
 * put it in localstorage or a cookie.
 */

let _accessToken: string;

export function setAccessToken(token: string) {
  _accessToken = token;
}

export function getAccessToken() {
  return _accessToken;
}

let _isSiteAdmin: boolean = false;

export function setSiteAdmin(value: boolean) {
  _isSiteAdmin = value;
}

export function isSiteAdmin(): boolean {
  return _isSiteAdmin;
}
