import React from "react";

import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { UnplannedTasks } from "../../pages/userApp/today/unplannedTasks";
import { PlanRole, PlanTask } from "../../util/modelTypes";

import { selectTodayPageState, setAddTaskInFlight, setEditingInProgress } from "../../pages/userApp/todayPageSlice";
import { AddUnplannedTask } from "../../pages/userApp/today/addUnplannedTask";

import {
  apiAddUnplannedTask,
  apiDeleteUnplannedTask,
  apiEditUnplannedTaskName,
  apiReorderUnplannedTask,
  apiSetUnplannedTaskRole,
  localSetDefaultRoleIdForBacklogAddTaskToPlan,
} from "../../pages/userApp/today/api/today.api.backlog";

import { apiPlanTask } from "../../pages/userApp/today/api/today.api.plan.backlog";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectBacklogState, toggleBacklogFilterRoleIdSelected } from "../../pages/userApp/backlogSlice";
import {
  generateRolesList,
  shouldBlockPlanUIFromState,
  shouldBlockUnplannedUIFromState,
} from "../../pages/userApp/today/todayPageStateUtils";
import { RoleFilter } from "../../pages/userApp/today/roleFilter";
import { Stack, Typography } from "@mui/material";
import { selectRoutinesState } from "../../pages/userApp/routinesSlice";
import {
  TodayOptimisticAPIContext,
  localSetDefaultRoleIdForAddTaskToPlan,
} from "../../pages/userApp/today/api/today.api.plan";
import { selectSettingsState } from "../../pages/userApp/settings/settingsSlice";

interface Props {}

export const BacklogPanel: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  // Today page state that is used by the backlog drawer
  const todayPageState = useAppSelector(selectTodayPageState);
  const backlogState = useAppSelector(selectBacklogState);
  const routinesState = useAppSelector(selectRoutinesState);
  const settingsState = useAppSelector(selectSettingsState);

  const makeAPIContext = (client: ApolloClient<object>): TodayOptimisticAPIContext => {
    return { client, todayPageState, backlogState, routinesState, settingsState, dispatch };
  };

  const rolesList = generateRolesList(backlogState.roles);
  const shouldBlockPlanUI = shouldBlockPlanUIFromState(todayPageState, backlogState);
  const shouldBlockUnplannedUI = shouldBlockUnplannedUIFromState(todayPageState, backlogState);

  if (
    !todayPageState.initialized ||
    !backlogState.initialized ||
    !settingsState.initialized ||
    !routinesState.initialized
  ) {
    return <></>;
  }

  return (
    <Stack>
      <Stack data-testid="Backlog" sx={{ py: 1, top: 0, position: "sticky", backgroundColor: "#eee", zIndex: 10 }}>
        <ApolloConsumer>
          {(client) => (
            <Stack spacing={1} sx={{ mx: 2 }}>
              <AddUnplannedTask
                addTaskButtonDisabled={backlogState.addTaskInFlight}
                addTask={(name) =>
                  apiAddUnplannedTask(makeAPIContext(client), name, backlogState.defaultRoleId, (addTaskInFlight) =>
                    dispatch(setAddTaskInFlight({ addTaskInFlight })),
                  )
                }
                roles={backlogState.roles}
                rolesList={rolesList}
                defaultRoleForAddTask={backlogState.roles[backlogState.defaultRoleId] ?? rolesList[0]}
                setDefaultRoleForAddTask={(role: PlanRole) => {
                  localSetDefaultRoleIdForBacklogAddTaskToPlan(role.id, dispatch);
                  localSetDefaultRoleIdForAddTaskToPlan(role.id, dispatch);
                }}
              />
              <RoleFilter
                rolesList={rolesList}
                roles={backlogState.roles}
                selectedRoles={backlogState.selectedRoles}
                toggleSelectRoleId={(roleId: string) => {
                  dispatch(toggleBacklogFilterRoleIdSelected({ roleId }));
                }}
              />
            </Stack>
          )}
        </ApolloConsumer>
      </Stack>
      <Stack sx={{ flex: "1 1 auto", mx: 2, my: 2 }}>
        {backlogState.unplannedTasks.length === 0 ? (
          <Typography data-testid="NoUnplannedTasks">
            <em>There are no tasks in the backlog</em>
          </Typography>
        ) : (
          <Stack sx={{ flex: "1 1 auto", borderRadius: 2, backgroundColor: "#fff", p: 1 }}>
            <ApolloConsumer>
              {(client) => (
                <UnplannedTasks
                  shouldBlockUnplannedUI={shouldBlockUnplannedUI}
                  shouldBlockPlanUI={shouldBlockPlanUI}
                  setEditingInProgress={(editingInProgress: boolean) =>
                    dispatch(setEditingInProgress({ editingInProgress }))
                  }
                  unplannedTasks={backlogState.unplannedTasks}
                  roles={backlogState.roles}
                  rolesList={rolesList}
                  selectedRoles={backlogState.selectedRoles}
                  planTask={(task) => apiPlanTask(makeAPIContext(client), task)}
                  editTaskName={(task, newName) => apiEditUnplannedTaskName(makeAPIContext(client), task, newName)}
                  deleteTask={(task) => apiDeleteUnplannedTask(makeAPIContext(client), task)}
                  reorderTask={(oldIndex, newIndex) =>
                    apiReorderUnplannedTask(makeAPIContext(client), oldIndex, newIndex)
                  }
                  setTaskRole={(task: PlanTask, role: PlanRole) => {
                    apiSetUnplannedTaskRole(makeAPIContext(client), task, role);
                  }}
                />
              )}
            </ApolloConsumer>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
