import { addNewProject, removeProject, updateProject } from "../../todayPageSlice";
import { requestCreateUserProject, requestUpdateUserProject } from "../../../../generated/graphqlWrappers";
import { showSnackbarError } from "../../../../components/appSnackbarSlice";
import { PlanProject } from "../../../../util/modelTypes";
import { optimisticUpdateAndServerRequestWithUndo } from "./api.utils";
import { apiUpdateProjectScoreboard } from "./today.api.taskGroup";
import { TodayOptimisticAPIContext } from "./today.api.plan";

export const apiAddProject = (
  apiContext: TodayOptimisticAPIContext,
  project: PlanProject,
  onSuccess: () => void,
  onError: (err: string) => void,
) => {
  const { client, dispatch } = apiContext;
  const {
    id: projectId,
    name,
    roleId,
    color,
    showOnScoreboard,
    startDate,
    endDate,
    weeklyPaceTimeBlockCount,
    weekStartsDayOfWeek,
  } = project;
  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: addNewProject({
      projectId,
      name,
      roleId,
      color,
      showOnScoreboard,
      startDate,
      endDate,
      weeklyPaceTimeBlockCount,
      weekStartsDayOfWeek,
    }),
    request: requestCreateUserProject,
    variables: {
      projectId,
      roleId,
      name,
      color,
      showOnScoreboard,
      startDate,
      endDate,
      weeklyPaceTimeBlockCount,
      weekStartsDayOfWeek,
    },
    undo: removeProject({ projectId }),
  });

  // we always end on success because errors are handled by the optimistic update
  onSuccess();
};

export const apiUpdateProject = (apiContext: TodayOptimisticAPIContext, project: PlanProject) => {
  const { client, dispatch, todayPageState } = apiContext;
  const oldProject = todayPageState.projects[project.id];
  if (!oldProject) {
    dispatch(showSnackbarError("Cannot update project"));
    return;
  }
  const { id: projectId, name, color, showOnScoreboard, startDate, endDate } = project;
  const { weeklyPaceTimeBlockCount, weekStartsDayOfWeek } = project;
  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: updateProject({
      projectId,
      name,
      color,
      showOnScoreboard,
      startDate,
      endDate,
      weeklyPaceTimeBlockCount,
      weekStartsDayOfWeek,
    }),
    request: requestUpdateUserProject,
    variables: {
      projectId,
      name,
      color,
      showOnScoreboard,
      startDate,
      endDate,
      weeklyPaceTimeBlockCount,
      weekStartsDayOfWeek,
    },
    onEnd: () => {
      apiUpdateProjectScoreboard(apiContext, projectId);
    },
    undo: updateProject({
      projectId,
      name: oldProject.name,
      color: oldProject.color,
      showOnScoreboard: oldProject.showOnScoreboard,
      startDate: oldProject.startDate,
      endDate: oldProject.endDate,
      weeklyPaceTimeBlockCount: oldProject.weeklyPaceTimeBlockCount,
      weekStartsDayOfWeek: oldProject.weekStartsDayOfWeek,
    }),
  });
};
