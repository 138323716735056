import { Dictionary, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PlanRole, PlanTask } from "../../util/modelTypes";
import { RootState } from "../../store/store";
import { BacklogQuery } from "../../generated/graphql";
import { addUnplannedTaskUtil } from "./today/todayPageStateUtils";
import { localToggleSelectedRole } from "./today/api/today.api.plan.backlog";

export interface BacklogState {
  initialized: boolean;
  roles: Dictionary<PlanRole>;
  selectedRoles: Dictionary<PlanRole>;
  defaultRoleId: string;
  unplannedTasks: PlanTask[];
  addTaskInFlight: boolean;
  editingInProgress: boolean;
}

const initialState: BacklogState = {
  initialized: false,
  roles: {},
  selectedRoles: {},
  defaultRoleId: "",
  unplannedTasks: [],
  addTaskInFlight: false,
  editingInProgress: false,
};

export const backlogSlice = createSlice({
  name: "backlog",
  initialState,
  reducers: {
    clearBacklogState: (state) => {
      state.initialized = false;
      state.roles = initialState.roles;
      state.selectedRoles = initialState.selectedRoles;
      state.defaultRoleId = initialState.defaultRoleId;
      state.unplannedTasks = initialState.unplannedTasks;
      state.addTaskInFlight = initialState.addTaskInFlight;
      state.editingInProgress = initialState.editingInProgress;
    },
    initializeBacklogState: (
      state,
      action: PayloadAction<{ data: BacklogQuery; selectedRoleIds: string[] | undefined }>,
    ) => {
      const { data, selectedRoleIds } = action.payload;
      state.initialized = true;
      state.unplannedTasks = data.userUnplannedTasks.map((task) => ({
        id: task.id,
        name: task.name,
        order: task.order,
        completed: task.completed,
        roleId: task.role.id,
      }));
      state.roles = data.userAccount.roles.reduce((roles, role) => {
        roles[role.id] = role;
        return roles;
      }, {} as Dictionary<PlanRole>);
      state.selectedRoles =
        selectedRoleIds === undefined
          ? state.roles
          : selectedRoleIds.reduce((roles, roleId) => {
              roles[roleId] = state.roles[roleId];
              return roles;
            }, {} as Dictionary<PlanRole>);

      state.defaultRoleId = data.userAccount.roles[0].id;
    },
    setUnplannedTaskRole: (state, action: PayloadAction<{ id: string; roleId: string }>) => {
      const task = state.unplannedTasks.find((task) => task.id === action.payload.id);
      if (task) {
        task.roleId = action.payload.roleId;
      }
    },
    reorderUnplannedTask: (state, action: PayloadAction<{ oldIndex: number; newIndex: number; newOrder: number }>) => {
      const { oldIndex, newIndex, newOrder } = action.payload;
      if (oldIndex === newIndex) {
        return;
      }
      const task = state.unplannedTasks.splice(oldIndex, 1)[0];
      task.order = newOrder;
      state.unplannedTasks.splice(newIndex, 0, task);
    },
    unplanTask: (state, action: PayloadAction<{ taskId: string; name: string; order: number; roleId: string }>) => {
      const task: PlanTask = {
        id: action.payload.taskId,
        ...action.payload,
        completed: false,
      };
      // Put the task in the unplanned list in the right order
      const index = state.unplannedTasks.findIndex((t) => t.order > task.order);
      if (index >= 0) {
        state.unplannedTasks.splice(index, 0, task);
      } else {
        state.unplannedTasks.push(task);
      }
    },
    addUnplannedTask: (
      state,
      action: PayloadAction<{ taskId: string; roleId: string; name: string; order: number }>,
    ) => {
      addUnplannedTaskUtil(state, action);
    },
    setUnplannedTaskName: (state, action: PayloadAction<{ id: string; name: string }>) => {
      const task = state.unplannedTasks.find((task) => task.id === action.payload.id);
      if (task) {
        task.name = action.payload.name;
      }
    },
    deleteUnplannedTask: (state, action: PayloadAction<{ taskId: string }>) => {
      state.unplannedTasks = state.unplannedTasks.filter((task) => task.id !== action.payload.taskId);
    },
    toggleBacklogFilterRoleIdSelected: (state, action: PayloadAction<{ roleId: string }>) => {
      localToggleSelectedRole(state, action.payload.roleId);
    },
    setDefaultBacklogRoleId: (state, action: PayloadAction<{ roleId: string }>) => {
      state.defaultRoleId = action.payload.roleId;
    },
  },
});

export const {
  clearBacklogState,
  initializeBacklogState,
  setUnplannedTaskRole,
  reorderUnplannedTask,
  unplanTask,
  addUnplannedTask,
  setUnplannedTaskName,
  deleteUnplannedTask,
  toggleBacklogFilterRoleIdSelected,
  setDefaultBacklogRoleId,
} = backlogSlice.actions;

export const selectBacklogState = (state: RootState) => state.backlog as BacklogState;

export default backlogSlice.reducer;
