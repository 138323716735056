import React from "react";

import TodayIcon from "@mui/icons-material/Today";
import { NavSidebar } from "./navSidebar";

const sidebar = [{ text: "Daily Plan", url: "/", icon: <TodayIcon /> }];

interface Props {
  chosen?: string;
}

export const AppSidebar: React.FC<Props> = ({ chosen }) => {
  return <NavSidebar chosen={chosen} sidebar={sidebar} />;
};
