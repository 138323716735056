import { Stack, TextField } from "@mui/material";
import React from "react";

interface Props {
  displayName: string;
  setDisplayName: (value: string) => void;
  email: string;
  setEmail: (value: string) => void;
}

export const InvitationDialogFields: React.FC<Props> = ({ email, setEmail, displayName, setDisplayName }) => {
  return (
    <Stack spacing={1}>
      <TextField
        label="Name"
        value={displayName}
        variant="outlined"
        sx={{ minWidth: 350 }}
        color="primary"
        onChange={(e) => setDisplayName(e.target.value)}
      />
      <TextField
        label="e-mail"
        id="user-text-field"
        type="email"
        value={email}
        variant="outlined"
        sx={{ minWidth: 350 }}
        color="primary"
        onChange={(e) => setEmail(e.target.value)}
      />
    </Stack>
  );
};
