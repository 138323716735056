import React from "react";
import { Badge, Button, Stack, Tooltip } from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import { planTasksToolbarButtonDefaultStyle } from "./planTasksToolbar";

interface Props {
  showRoutines: boolean;
  numberOfUnaddedRoutines: number;
  toggleShowRoutines: () => void;
}

export const ShowRoutinesButton: React.FC<Props> = ({ showRoutines, numberOfUnaddedRoutines, toggleShowRoutines }) => {
  const isDisabled = numberOfUnaddedRoutines === 0;
  const title = isDisabled
    ? "No more routines scheduled for today"
    : `${showRoutines ? "Hide" : "Show"} today's routines`;
  return (
    <Tooltip title={title}>
      <Stack>
        <Button
          sx={{
            position: "relative",
            height: "100%",
            ...planTasksToolbarButtonDefaultStyle,
          }}
          variant="outlined"
          onClick={toggleShowRoutines}
          disabled={isDisabled}
        >
          {numberOfUnaddedRoutines > 0 && (
            <Badge
              badgeContent={numberOfUnaddedRoutines}
              sx={{ position: "absolute", right: 5, top: 5 }}
              color="primary"
            />
          )}
          <LoopIcon />
        </Button>
      </Stack>
    </Tooltip>
  );
};
