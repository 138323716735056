import { ApolloClient } from "@apollo/client";
import {
  TodayPageState,
  setDailyPlanId,
  setDefaultRoleId,
  setEndTimeBlockNumber,
  setPlanBlock,
  setStartTimeBlockNumber,
  setTimerState,
  setTimerType,
} from "../../todayPageSlice";
import { AppDispatch } from "../../../../store/store";
import {
  requestCreateUserDailyPlanForDate,
  requestSetPlanEndTimeBlockNumber,
  requestSetPlanStartTimeBlockNumber,
  requestSetUserPlanBlock,
  requestTodayPage,
} from "../../../../generated/graphqlWrappers";
import { showSnackbarError, showSnackbarSuccess } from "../../../../components/appSnackbarSlice";
import { verifyDataMatchesClient } from "../utils/todayPageVerify";
import { TimerType } from "../utils/timerType";
import { TimerState } from "../utils/timerState";
import { RoleBlockType } from "../../../../util/modelTypes";
import { firstGroupWithIncompleteTasksInTimerRole } from "../todayPageStateUtils";
import { optimisticUpdateAndServerRequestWithUndo } from "./api.utils";
import { uuidForId } from "../../../../util/uuidUtils";
import { formatDateSimple } from "../../../../util/dateUtils";
import { apiSetCompletedTimeBlockCount } from "./today.api.taskGroup";
import { BacklogState } from "../../backlogSlice";
import { RoutinesState } from "../../routinesSlice";
import { SettingsState } from "../../settings/settingsSlice";

export interface TodayOptimisticAPIContext {
  client: ApolloClient<object>;
  todayPageState: TodayPageState;
  backlogState: BacklogState;
  routinesState: RoutinesState;
  settingsState: SettingsState;
  dispatch: AppDispatch;
}

export const apiSetStartTimeBlockNumber = (apiContext: TodayOptimisticAPIContext, startTimeBlockNumber: number) => {
  const { client, todayPageState, settingsState, dispatch } = apiContext;
  if (
    startTimeBlockNumber < 0 ||
    startTimeBlockNumber > 48 ||
    startTimeBlockNumber >= todayPageState.endTimeBlockNumber
  ) {
    return;
  }

  const oldDailyPlanId = todayPageState.dailyPlanId;
  const hasDailyPlan = todayPageState.dailyPlanId !== "";
  const dailyPlanId = hasDailyPlan ? todayPageState.dailyPlanId : uuidForId();

  const oldStartTimeBlockNumber = todayPageState.startTimeBlockNumber;
  if (!hasDailyPlan) {
    // The server will create a plan with this id and endTime.
    dispatch(setDailyPlanId({ dailyPlanId }));
    dispatch(setEndTimeBlockNumber({ endTimeBlockNumber: settingsState.defaultEndTimeBlockNumber }));
  }
  dispatch(setStartTimeBlockNumber({ startTimeBlockNumber }));
  requestSetPlanStartTimeBlockNumber(
    client,
    { dailyPlanId, date: todayPageState.date, startTimeBlockNumber },
    () => {},
    (err) => {
      dispatch(showSnackbarError(err));
      dispatch(setDailyPlanId({ dailyPlanId: oldDailyPlanId }));
      dispatch(setStartTimeBlockNumber({ startTimeBlockNumber: oldStartTimeBlockNumber }));
    },
  );
};

export const apiSetEndTimeBlockNumber = (apiContext: TodayOptimisticAPIContext, endTimeBlockNumber: number) => {
  const { client, todayPageState, dispatch } = apiContext;
  if (endTimeBlockNumber < 0 || endTimeBlockNumber > 48 || todayPageState.startTimeBlockNumber >= endTimeBlockNumber) {
    return;
  }

  const oldDailyPlanId = todayPageState.dailyPlanId;
  const hasDailyPlan = todayPageState.dailyPlanId !== "";
  const dailyPlanId = hasDailyPlan ? todayPageState.dailyPlanId : uuidForId();

  const oldEndTimeBlockNumber = todayPageState.endTimeBlockNumber;
  if (!hasDailyPlan) {
    // The server will create a plan with this id and startTime.
    dispatch(setDailyPlanId({ dailyPlanId }));
    dispatch(setStartTimeBlockNumber({ startTimeBlockNumber: todayPageState.startTimeBlockNumber }));
  }
  dispatch(setEndTimeBlockNumber({ endTimeBlockNumber }));
  requestSetPlanEndTimeBlockNumber(
    client,
    { dailyPlanId, date: todayPageState.date, endTimeBlockNumber },
    () => {},
    (err) => {
      dispatch(showSnackbarError(err));
      dispatch(setDailyPlanId({ dailyPlanId: oldDailyPlanId }));
      dispatch(setEndTimeBlockNumber({ endTimeBlockNumber: oldEndTimeBlockNumber }));
    },
  );
};

export function verifyServerMatchesClient(apiContext: TodayOptimisticAPIContext, today: Date) {
  const { client, todayPageState, dispatch } = apiContext;
  requestTodayPage(
    client,
    { date: formatDateSimple(today) },
    (data) => {
      try {
        verifyDataMatchesClient(data, todayPageState);
        dispatch(showSnackbarSuccess("Verified"));
      } catch (err) {
        if (err instanceof Error) {
          dispatch(showSnackbarError(err.message));
        }
      }
    },
    (err) => {
      dispatch(showSnackbarError(err));
    },
  );
}

export function apiCreatePlan(apiContext: TodayOptimisticAPIContext, today: Date) {
  const { client, todayPageState, settingsState, dispatch } = apiContext;
  if (todayPageState.dailyPlanId !== "") {
    return;
  }
  const dailyPlanId = uuidForId();
  const startTimeBlockNumber = settingsState.defaultStartTimeBlockNumber;
  const endTimeBlockNumber = settingsState.defaultEndTimeBlockNumber;

  dispatch(setDailyPlanId({ dailyPlanId }));
  dispatch(setStartTimeBlockNumber({ startTimeBlockNumber }));
  dispatch(setEndTimeBlockNumber({ endTimeBlockNumber }));
  requestCreateUserDailyPlanForDate(
    client,
    {
      dailyPlanId,
      date: formatDateSimple(today),
    },
    () => {},
    (err) => {
      dispatch(showSnackbarError(err));
      dispatch(setDailyPlanId({ dailyPlanId: "" }));
    },
  );
}

export const localSetDefaultRoleIdForAddTaskToPlan = (roleId: string, dispatch: AppDispatch) => {
  dispatch(setDefaultRoleId({ roleId }));
};

export function onDoEndTimerPromptAction(
  apiContext: TodayOptimisticAPIContext,
  autoCompleteTimeBlock: boolean,
  timerType: TimerType | null,
) {
  const { todayPageState, dispatch } = apiContext;
  if (autoCompleteTimeBlock) {
    const group = firstGroupWithIncompleteTasksInTimerRole(todayPageState);
    if (group) {
      apiSetCompletedTimeBlockCount(apiContext, group, group.completedTimeBlockCount + 1);
    }
  }
  if (timerType !== null) {
    dispatch(setTimerType({ timerType }));
    dispatch(setTimerState({ timerState: TimerState.Running }));
  }
}

export const apiSetPlanBlockType = (
  apiContext: TodayOptimisticAPIContext,
  blockNumber: number,
  block: RoleBlockType,
) => {
  const { client, todayPageState, settingsState, dispatch } = apiContext;
  const roleId = block.role?.id;
  const plannedBlockType = block.blockType;
  const oldBlock = todayPageState.plannedBlocks[blockNumber];
  if (oldBlock === undefined) {
    dispatch(showSnackbarError("Cannot set block type"));
    return;
  }
  const oldRoleId = oldBlock.role?.id;
  const oldPlannedBlockType = oldBlock.blockType;

  const oldDailyPlanId = todayPageState.dailyPlanId;
  const hasDailyPlan = todayPageState.dailyPlanId !== "";
  const dailyPlanId = hasDailyPlan ? todayPageState.dailyPlanId : uuidForId();

  const setDailyPlanDefaultsIfNeeded = hasDailyPlan
    ? []
    : [
        setDailyPlanId({ dailyPlanId }),
        setStartTimeBlockNumber({ startTimeBlockNumber: settingsState.defaultStartTimeBlockNumber }),
        setEndTimeBlockNumber({ endTimeBlockNumber: settingsState.defaultEndTimeBlockNumber }),
      ];

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: [setPlanBlock({ blockNumber, roleId, plannedBlockType }), ...setDailyPlanDefaultsIfNeeded],
    request: requestSetUserPlanBlock,
    variables: {
      dailyPlanId,
      date: todayPageState.date,
      blockNumber,
      roleId,
      plannedBlockType,
    },
    undo: [
      setPlanBlock({ blockNumber, roleId: oldRoleId, plannedBlockType: oldPlannedBlockType }),
      setDailyPlanId({ dailyPlanId: oldDailyPlanId }),
    ],
  });
};
