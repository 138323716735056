import React from "react";
import { Box, Stack, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { range } from "../../util/arrayUtils";

interface Props {
  timeBlockCount: number;
  timeBlocksToRender: number;
  completedTimeBlockCount?: number;
  timeBlockOpacity: (timeBlockIndex: number) => number;
  onTimeBlockClick: (timeBlockIndex: number) => void;
  onCompletedTimeBlockClick?: (timeBlockIndex: number) => void;
  colorForRole: () => string;
  small?: boolean;
  isGray?: boolean;
}

export const TimeBlockButtons: React.FC<Props> = ({
  timeBlockCount,
  timeBlocksToRender,
  completedTimeBlockCount,
  timeBlockOpacity,
  onTimeBlockClick,
  onCompletedTimeBlockClick,
  colorForRole,
  small,
  isGray,
}) => {
  const plannedTooltipTitle = `${timeBlockCount} Time Block${timeBlockCount === 1 ? "" : "s"} Planned`;
  const tooltipTitle = onCompletedTimeBlockClick
    ? `${plannedTooltipTitle} and ${completedTimeBlockCount} Completed`
    : plannedTooltipTitle;

  const margin = completedTimeBlockCount !== undefined ? 1 : 0.5;
  const size = small ? 15 : 30;
  const outerSizePx = `${size}px`;
  const innerSizePx = `${size - (small ? 4 : 8)}px`;
  const innerMarginPx = `${small ? 2 : 4}px`;
  const blocksWidth = `${(size + 2 + margin * 8 * 2) * 5}px`;

  const backgroundColor = (i: number) => {
    if (i >= timeBlockCount) {
      return undefined;
    }
    return isGray ? "#ccc" : colorForRole();
  };

  const borderColor = (i: number) => {
    if (i >= timeBlockCount) {
      return undefined;
    }
    return colorForRole();
  };

  const timeBlockClick = (i: number) => {
    if (i + 1 === timeBlockCount) {
      onTimeBlockClick(i - 1);
    } else {
      onTimeBlockClick(i);
    }
  };

  return (
    <Tooltip enterDelay={1000} title={tooltipTitle} placement="top" arrow>
      <Stack direction="row" flexWrap="wrap" sx={{ mb: "auto", width: blocksWidth, minWidth: blocksWidth }}>
        {range(timeBlocksToRender).map((i) => (
          <Stack key={i} direction="row">
            <Box
              sx={{
                border: `1px solid black`,
                width: outerSizePx,
                height: outerSizePx,
                backgroundColor: borderColor(i),
                margin,
                cursor: "pointer",
                zIndex: 1,
                transformStyle: "preserve-3d",
                opacity: timeBlockOpacity(i),
              }}
              onClick={() => timeBlockClick(i)}
            >
              <Box
                sx={{
                  width: innerSizePx,
                  height: innerSizePx,
                  ml: innerMarginPx,
                  mt: innerMarginPx,
                  backgroundColor: backgroundColor(i),
                  cursor: "pointer",
                  zIndex: 1,
                  transformStyle: "preserve-3d",
                  opacity: timeBlockOpacity(i),
                }}
              />
            </Box>
            {onCompletedTimeBlockClick !== undefined && completedTimeBlockCount !== undefined ? (
              <Box
                sx={{
                  width: "15px",
                  height: "15px",
                  border: "1px solid",
                  backgroundColor: i < completedTimeBlockCount ? "#eee" : "#eee",
                  cursor: "pointer",
                  my: 0.5,
                  zIndex: 1,
                  ml: "-20px",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyItems: "center",
                }}
                data-testid="TimeBlockCheckbox"
                onClick={() => {
                  if (i + 1 === completedTimeBlockCount) {
                    onCompletedTimeBlockClick(i - 1);
                  } else {
                    onCompletedTimeBlockClick(i);
                  }
                }}
              >
                {i < completedTimeBlockCount ? <CheckIcon /> : <></>}
              </Box>
            ) : (
              <></>
            )}
          </Stack>
        ))}
      </Stack>
    </Tooltip>
  );
};
