import React from "react";
import { PlanTask } from "../../../util/modelTypes";
import { ModalTextField } from "../../../components/modalTextField";

interface Props {
  task: PlanTask;
  taskName: string;
  setTaskName: (taskName: string) => void;
  editTaskName: (task: PlanTask, newName: string) => void;
  onEditSaved: () => void;
  onEditCancelled: () => void;
}

export const PlannedTaskTextField: React.FC<Props> = ({
  task,
  taskName,
  setTaskName,
  editTaskName,
  onEditSaved,
  onEditCancelled,
}) => {
  return (
    <ModalTextField
      id={task.id}
      allowEmpty={false}
      value={taskName}
      originalValue={task.name}
      onChange={(v) => setTaskName(v)}
      saveEdit={() => {
        editTaskName(task, taskName);
        onEditSaved();
      }}
      cancelEdit={onEditCancelled}
    />
  );
};
