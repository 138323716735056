import React, { useEffect } from "react";

import { Box, Drawer, IconButton, Stack, Tab, Tooltip, Typography } from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { AppLogo } from "../appLogo";
import { UserAppBar } from "../userAppBar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ViewListIcon from "@mui/icons-material/ViewList";
import MenuIcon from "@mui/icons-material/Menu";
import Cookies from "js-cookie";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  initializeAppState,
  selectAppState,
  setAppBarHidden,
  setBacklogDrawerIsOpen,
  setSidebarDrawerIsOpen,
} from "../../pages/appSlice";
import { BacklogPanel } from "../app/backlogPanel";
import { BacklogStateLoader } from "../app/backlogStateLoader";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { RoutinesPanel } from "../app/routinesPanel";
import { RoutinesStateLoader } from "../app/routinesStateLoader";
import { SettingsStateLoader } from "../app/settingsStateLoader";

interface Props {
  children: React.ReactNode;
  sidebar: React.ReactNode;
  rightDrawerWidth?: number;
}

export const LayoutLeftNav: React.FC<Props> = ({ children, sidebar, rightDrawerWidth }) => {
  const [rightDrawerTab, setRightDrawerTab] = React.useState("1");
  const appState = useAppSelector(selectAppState);
  const dispatch = useAppDispatch();

  // Initialize app state from cookies
  const sidebarDrawerIsOpenCookie = Cookies.get("drawerIsOpen") !== "false";
  useEffect(() => {
    if (!appState.isInitialized) {
      dispatch(initializeAppState({ sidebarDrawerIsOpen: sidebarDrawerIsOpenCookie, backlogDrawerIsOpen: false }));
    }
  }, [dispatch, sidebarDrawerIsOpenCookie, appState.sidebarDrawerIsOpen, appState.isInitialized]);
  Cookies.set("drawerIsOpen", appState.sidebarDrawerIsOpen.toString());

  useEffect(() => {
    if (window.scrollY === 0 && appState.appBarHidden) {
      dispatch(setAppBarHidden(false));
    }
  }, [dispatch, appState.appBarHidden]);

  const leftDrawerWidth = appState.sidebarDrawerIsOpen ? 240 : 0;
  const backlogDrawerWidth = 600;
  const animationProperties = {
    transition: "ease-out",
    transitionDuration: "100ms",
    transitionProperty: "width",
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", height: "100vh" }}>
        <Drawer
          variant="persistent"
          open={appState.sidebarDrawerIsOpen}
          sx={{
            ...animationProperties,
            width: leftDrawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { ...animationProperties, width: leftDrawerWidth, boxSizing: "border-box" },
          }}
        >
          {window.location.hostname === "localhost" ? (
            <Typography sx={{ backgroundColor: "red" }} textAlign="center">
              Development
            </Typography>
          ) : (
            <></>
          )}
          <IconButton onClick={() => dispatch(setSidebarDrawerIsOpen(false))} sx={{ alignSelf: "flex-end" }}>
            <ChevronLeftIcon />
          </IconButton>
          <AppLogo />
          {sidebar}
        </Drawer>
        <Stack sx={{ ...animationProperties, flexGrow: 1, ml: 3, mt: 1, mr: `${(rightDrawerWidth ?? 0) + 24}px` }}>
          <Stack direction="row">
            <Stack
              direction="row"
              visibility={!appState.appBarHidden && !appState.sidebarDrawerIsOpen ? "visible" : "hidden"}
            >
              <Tooltip title="Sidebar">
                <IconButton onClick={() => dispatch(setSidebarDrawerIsOpen(true))} sx={{ alignSelf: "center" }}>
                  <MenuIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Stack direction="row" visibility={!appState.appBarHidden ? "visible" : "hidden"}>
              <UserAppBar />
              <Tooltip title="Backlog and Routines">
                <IconButton
                  data-testid="RightSidebarButton"
                  onClick={() => dispatch(setBacklogDrawerIsOpen(true))}
                  sx={{ alignSelf: "center" }}
                >
                  <ViewListIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
          <Box component="main" sx={{ flexGrow: 1, mt: 3 }} justifyContent="center">
            {children}
          </Box>
          <Drawer
            variant="temporary"
            anchor="right"
            open={appState.backlogDrawerIsOpen}
            onClose={() => dispatch(setBacklogDrawerIsOpen(false))}
            sx={{
              width: backlogDrawerWidth,
              flexShrink: 0,

              [`& .MuiDrawer-paper`]: { backgroundColor: "#eee", width: backlogDrawerWidth, boxSizing: "border-box" },
            }}
          >
            <Stack sx={{ height: "100vh" }}>
              <TabContext value={rightDrawerTab}>
                <Stack direction="row">
                  <IconButton
                    data-testid="CloseRightSidebarButton"
                    onClick={() => dispatch(setBacklogDrawerIsOpen(false))}
                    sx={{ alignSelf: "center" }}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                  <TabList
                    onChange={(event, t) => {
                      setRightDrawerTab(t);
                    }}
                  >
                    <Tab label="Backlog" value="1" />
                    <Tab label="Routines" value="2" />
                  </TabList>
                </Stack>
                <TabPanel value="1" sx={{ p: 0, my: 1 }}>
                  <BacklogPanel />
                </TabPanel>
                <TabPanel value="2">
                  <RoutinesPanel />
                </TabPanel>
              </TabContext>
            </Stack>
          </Drawer>
        </Stack>
      </Box>
      <BacklogStateLoader />
      <RoutinesStateLoader />
      <SettingsStateLoader />
    </React.Fragment>
  );
};
