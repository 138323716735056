export enum TimerType {
  Work,
  ShortBreak,
  LongBreak,
}

export const parseTimerType = (s: String) => {
  switch (s) {
    case "Work":
      return TimerType.Work;
    case "ShortBreak":
      return TimerType.ShortBreak;
    case "LongBreak":
      return TimerType.LongBreak;
  }
  return TimerType.Work;
};

export const formatTimerType = (type: TimerType) => {
  switch (type) {
    case TimerType.Work:
      return "Work";
    case TimerType.ShortBreak:
      return "ShortBreak";
    case TimerType.LongBreak:
      return "LongBreak";
  }
};

export const timeForTimerType = (type: TimerType) => {
  switch (type) {
    case TimerType.Work:
      return 25 * 60;
    case TimerType.ShortBreak:
      return 3 * 60;
    case TimerType.LongBreak:
      return 15 * 60;
  }
};
