import React from "react";
import { AppLayout } from "./layouts/appLayout";

interface Props {
  chosen?: string;
  children?: React.ReactNode;
}

export const AppLoadingPage: React.FC<Props> = ({ chosen, children }) => {
  return <AppLayout chosen={chosen}>{children}</AppLayout>;
};
